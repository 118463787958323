import {
    VASTU_FETCH_SUCCESS,
    VASTU_FETCH_FAILED,
    MUHURAT_FETCH_SUCCESS,
    MUHURAT_FETCH_FAILED,
    HANDMADE_REPORT_FETCH_SUCCESS,
    HANDMADE_REPORT_FETCH_FAILED,
} from '../actions/types';

const INITIALSTATE = {};

export default (state = INITIALSTATE, action) => {
    switch (action.type) {
        case MUHURAT_FETCH_SUCCESS: {
            return {
                ...state,
                muhurat: action.data,
            };
        }
        case VASTU_FETCH_SUCCESS: {
            return {
                ...state,
                vastu: action.data,
            };
        }
        case HANDMADE_REPORT_FETCH_SUCCESS: {
            return {
                ...state,
                kundli: action.data,
            };
        }
        default:
            return state;
    }
};
