import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector, useDispatch } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import SideBar from './sidebar/SideBar';
import Content from './content/Content';
import { askForPermission } from '../../PushNotification';
import { notifyUser } from '../../store/actions/push';
import Pusher from './pusher';
import IdleTimerContainer from './IdleTimerContainer';
import useSound from 'use-sound';
import playalert from '../../assets/music/simple_bell.mp3';
import Dialog from './Chat/Dialog';
// import loader from './loader';
import '../../assets/css/dashboard.style.css';

const Astrologer = (props) => {

    const push = useSelector((state) => state.push);
    const dispatch = useDispatch();
    const [play, { stop }] = useSound(playalert);

    let astro;
    astro = useSelector((state) => {
        return state.user;
    });
    const [showDialog, setShowDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    let userId;

    const [isOpen, setOpen] = useState(true);
    const toggle = () => setOpen(!isOpen);

    useEffect(() => {
        askForPermission();
    }, []);

    useEffect(() => {
        const isMobile = window.innerWidth < 480;
        if (isMobile) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        const channel = Pusher.subscribe('chat');
        channel.bind('user-chat-request', (data) => {
            userId = data.userId;

            if (data.astroId === astro.data.id) {
                play();
                confirmAlert(options);
                play();
            }
        });
        channel.bind('user-chat-cancel', (data) => {
            userId = data.userId;
            if (data.astroId === astro.data.id) {
                let option = {
                    title: 'Chat Request Cancelled',
                    message: 'Chat Request Cancelled...',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                stop();
                            },
                        },
                    ],
                };
                confirmAlert(option);

            }
        });

        return () => channel.unbind();
    }, [astro]);

    const chatRequestAccepted = (id) => {
        dispatch(
            notifyUser({
                userId: userId,
                astroId: astro.data.id,
                message: 'accepted',
                action: 'chat accepted by astro',
                sender: 'astro',
                receiver: 'user',
            })
        );
        setShowDialog(true);
        setDialogMessage("Please wait while we are connecting you with the user");
        setTimeout(() => {
            setShowDialog(false);
            setDialogMessage("");
            props.history.push('/astrologer/chat');
        }, 2000);
    };

    const chatRequestDeclined = (id) => {
        dispatch(
            notifyUser({
                userId: userId,
                astroId: astro.data.id,
                message: 'rejected',
                action: 'chat rejected by astro',
                sender: 'astro',
                receiver: 'user',
            })
        );
    };

    const options = {
        title: 'Chat request',
        message: 'Chat Request received...',
        buttons: [
            {
                label: 'Accept',
                onClick: () => {
                    chatRequestAccepted();
                    stop();
                },
            },
            {
                label: 'Decline',
                onClick: () => {
                    chatRequestDeclined();
                    stop();
                },
            },
        ],
    };
    return (
        <>
            <div className="App wrapper">
                <IdleTimerContainer />
                <SideBar toggle={toggle} isOpen={isOpen} />
                <Content toggle={toggle} isOpen={isOpen} />
            </div>
            <Dialog
                open={showDialog}
                toggle={false}
                className="my-model"
                dismiss
                message={dialogMessage}
            />
        </>
    );
};

export default Astrologer;
