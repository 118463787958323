import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faImage,
    faHistory,
    faCommentAlt,
    faPhoneSquareAlt,
    faCoins,
} from '@fortawesome/free-solid-svg-icons';

import { NavItem, NavLink, Nav } from 'reactstrap';

import SubMenu from './SubMenu';
const url = process.env.REACT_APP_BASE_API;

const SideBar = (props) => {
    const [user, setUser] = useState('');
    const [data, setData] = useState('')
    const getCurrentStatus = async () => {
        const res = await axios.get(url + '/astro/getStatus/astro', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setUser(res.data.data.userId);
            setData(res.data.data);
        }
    };

    useEffect(() => {
        getCurrentStatus();
    }, [])

    return <div className={classNames('sidebar', { 'is-open': props.isOpen })}>
        <div className="sidebar-header">
            <span color="info" onClick={props.toggle} style={{ color: '#000' }}>
                &times;
            </span>
            <h3>Jyotirvid</h3>
        </div>
        <div className="side-menu">
            <Nav vertical className="list-unstyled pb-3">
                <p>Menu</p>
                <NavItem>
                    <NavLink
                        tag={Link}
                        to={'/astrologer/dashboard'}
                        style={{ borderBottom: '#ff9900' }}
                    >
                        <FontAwesomeIcon icon={faHome} className="mr-2" />
                        Dashboard
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={Link} to={'/astrologer/profile'}>
                        <FontAwesomeIcon icon={faImage} className="mr-2" />
                        Edit Profile
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={'/astrologer/chat'}>
                        <FontAwesomeIcon icon={faCommentAlt} className="mr-2" />
                        Chat Box
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={'/astrologer/chat-history'}>
                        <FontAwesomeIcon icon={faHistory} className="mr-2" />
                        Chat History
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={'/astrologer/call-history'}>
                        <FontAwesomeIcon icon={faHistory} className="mr-2" />
                        Call History
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={{pathname: '/astrologer/earning', state: {id:user}}}>
                        <FontAwesomeIcon icon={faCoins} className="mr-2" />
                        Earning
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={{pathname: '/astrologer/account', state: data}}>
                        <FontAwesomeIcon icon={faCoins} className="mr-2" />
                        Account
                    </NavLink>
                </NavItem>
                {/* <SubMenu title="Pages" icon={faCopy} items={submenus[0]} /> */}

                <NavItem>
                    <NavLink tag={Link} to={'/astrologer/menu-3'}>
                        <FontAwesomeIcon
                            icon={faPhoneSquareAlt}
                            className="mr-2"
                        />
                        Contact Us
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    </div>
};

// const submenus = [
//     [
//         {
//             title: 'Page 1',
//             target: 'page-1',
//         },
//         {
//             title: 'Page 2',
//             target: 'page-2',
//         },
//     ],
// ];

export default SideBar;
