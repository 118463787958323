import { combineReducers } from 'redux';
// import { getEarning } from '../actions/astroProfile';

import adminAuth from './adminAuth';
import astroAuth from './astroAuth';
// import earnReducer from './earnReducer';
import pushReducer from './pushReducer';
import serviceReducer from './serviceReducer';

const rootReducer = combineReducers({
    user: astroAuth,
    admin: adminAuth,
    push: pushReducer,
    services: serviceReducer,
    // earning: earnReducer,
});

export default rootReducer;
