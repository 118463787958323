import React from 'react';

import sendImage from '../../../assets/images/send.imageset/Path@2x.png';

const Input = ({ message, setMessage, sendMessage, saveMessage }) => {
    const handleMessage = () => {};

    return (
        <div className="message-input">
            <div className="wrap">
                <input
                    type="text"
                    placeholder="Write your message..."
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyPress={(event) =>
                        event.key === 'Enter' ? sendMessage(event) : null
                    }
                />
                <img
                    src={sendImage}
                    alt=""
                    onClick={(event) => sendMessage(event)}
                />
            </div>
        </div>
    );
};

export default Input;
