import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import { Form, Input, Row, Col } from "reactstrap";
import styles from '../../../Styles/Admin.module.css'
import moment from 'moment'

const Index = () => {
    const [userVideoCallMissedList, setverifyedUsersList] = useState([])
    const { location } = useHistory()
    const userVideoCallMissedListDetails = location.state.userVideoCallMissedList
    const startDate = location.state.startDate
    const endDate = location.state.endDate
    const formik = useFormik({
        initialValues: {
            startDate: startDate,
            endDate: endDate,
        },

    });
    const style =
    {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px'
    }

    return (
        <>
            <div style={{ padding: '10px' }}>
                <Form
                    onSubmit={formik.handleSubmit} >
                    <Row>
                        <Col md={2} >
                            <Input
                                type="date"
                                name="startDate"
                                value={formik.values.startDate}
                            />
                        </Col>
                        <Col md={2}>
                            <Input
                                type="date"
                                name="endDate"
                                value={formik.values.endDate}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <div style={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '5px' }}>
                    <h4 style={{ textAlign: 'center' }}>User Video Call Missed</h4>
                    <div style={style}>
                        <div style={{ width: '50%' }}>
                            {userVideoCallMissedList && userVideoCallMissedList}
                        </div>
                    </div>
                    <div className={styles.callMissed}>
                        <table
                            class="table table-striped table-bordered" l
                            style={{
                                border: '1px solid gray',
                                borderRadius: '3px',
                                backgroundColor: 'white',
                            }}
                        >
                            <thead className={styles.onOff1}>
                                <tr>
                                    <th>S.No.</th>
                                    <th>User Name - Mobile No.</th>
                                    <th>Astro Name - Mobile No.</th>
                                    <th>Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userVideoCallMissedListDetails && userVideoCallMissedListDetails.length > 0 ? userVideoCallMissedListDetails.map((data, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.user.name + "/" + data.user.mobile}</td>
                                        <td>{data.astro.name + "/" + data.astro.mobile}</td>
                                        <td>{moment(data.createdAt).format('llll')}</td>
                                    </tr>
                                }):(
                                    <p>*There isn't present any data yet.</p>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index
