import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../Styles/Admin.module.css'
import moment from 'moment'

const Index = () => {
    const { location } = useHistory()
    const videoCallListDetail = location.state.videoCallList;
    const astro = location.state.astro

    const style = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px'
    }
    return (

        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <div style={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '5px' }}>
                    <h4 style={{ textAlign: 'center' }}>{astro && astro} : All Video Call Details </h4>
                    <div style={style}>
                        <div style={{ width: '50%' }}>
                        </div>
                    </div>
                    <div className={styles.astroView}>
                        <table
                            class="table table-striped table-bordered" l
                            style={{
                                border: '1px solid gray',
                                borderRadius: '3px',
                                backgroundColor: 'white',
                            }}
                        >
                            <thead className={styles.astroView1}>
                                <tr>
                                    <th>S.No.</th>
                                    <th>User Name & Mobile No</th>
                                    <th>Call Rate</th>
                                    <th>Amount Charged</th>
                                    <th>Call Duration</th>
                                    <th>Video End By</th>
                                    {/* <th>Start Time</th> */}
                                    <th>Call Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {videoCallListDetail.rows && videoCallListDetail.rows.length > 0 && videoCallListDetail.rows.map((data, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.user.name + "/" + data.user.mobile}</td>
                                        <td>{data.videoCallRate}</td>
                                        <td>{data.amountCharged}</td>
                                        <td>{data.videoCallDuration}</td>
                                        <td>{data.videoEndBy}</td>
                                        {/* <td>{moment(data.startTime, 'HH:mm:ss').add(data.startTime, 'minutes').format('HH:mm:ss').toLocaleString()}</td> */}
                                        <td>{moment(data.startTime).format('llll')}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;
