import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert } from 'reactstrap';

const url = process.env.REACT_APP_BASE_API;

const ForgotPassword = (props) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const [visible, setVisible] = useState(true);

    const onDismiss = () => setVisible(false);

    const postForgotPassword = async (formData) => {
        try {
            const rawResponse = await fetch(url + '/account/forgotPassword', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'post',
                body: JSON.stringify(formData),
            });
            const result = await rawResponse.json();
            if (result.status === 'success') {
                setIsSuccess(true);
                setIsError(false);
                setMessage(
                    'An email with password reset link is sent to your email.'
                );
                formik.setSubmitting(false);
            }
            if (result.status === 'fail') {
                setIsError(true);
                setError(result.message);
                formik.setSubmitting(false);
            }
        } catch (err) {
            if (err.status === 429) {
                setIsError(true);
                setError(
                    'Too many request from this IP. Try again in an hour.'
                );
                setIsError(true);
            }
            if (err.response) {
                setIsError(true);
                setError('Something went wrong! Try again later.');
            } else if (err.request) {
                setIsError(true);
                setError('Something went wrong! Try again later.');
            } else {
                setIsError(true);
                setError('Something went wrong! Try again later.');
            }
        }
    };

    const formik = useFormik({
        initialValues: { email: '', password: '' },
        onSubmit: (values) => {
            postForgotPassword(values);
        },
        validationSchema: () =>
            Yup.object().shape({
                email: Yup.string()
                    .required('Email is required.')
                    .email('Enter the valid email.'),
            }),
    });
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="login100-form validate-form"
                    >
                        <span className="login100-form-title p-b-26">
                            Forgot Password
                        </span>

                        <div>
                            Please enter your email address below. Further
                            instructions will be sent to you on email.
                        </div>
                        {isError && (
                            <div style={{ marginBottom: '15px', color: 'red' }}>
                                {error}
                            </div>
                        )}
                        {isSuccess && (
                            <Alert
                                color="info"
                                isOpen={visible}
                                toggle={onDismiss}
                            >
                                {message}
                            </Alert>
                        )}
                        <div className="wrap-input100 validate-input">
                            <input
                                className="input100"
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </div>
                        {formik.errors.email && formik.touched.email && (
                            <div className="input-error">
                                {formik.errors.email}
                            </div>
                        )}

                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button
                                    type="submit"
                                    className="login100-form-btn"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                        <div className="text-center p-t-115">
                            <span className="txt3">
                                <Link to="/login">Back to Login Page</Link>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
