import React, { useState, useEffect, Fragment } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import styles from '../../Styles/Admin.module.css'
import { Card } from "reactstrap";
const url = process.env.REACT_APP_BASE_API

const Index = () => {
    const navigate = useHistory();
    const [astro, setAstro] = useState();
    const [astroDetails, setAstroDetails] = useState();
    const [callDetails, setCallDetails] = useState();
    const [videoCallList, setVideoCalldetails] = useState()
    const [videoCallMissed, setvideoCallMissed] = useState()
    const [chatDetails, setChatDetails] = useState();
    const [astroOnlineOffineActivity, setAstroOnlineOffineActivity] = useState();
    const [chatMissedDetails, setChatMissedDetails] = useState();
    const [callMissedDetails, setCallMissedDetails] = useState();
    const [loading, setloading] = useState(false)

    const { location } = useHistory()
    const id = location.state.data
    const getAstrologer = async () => {
        await axios.get(url + `/admin/astrologer-details?astroId=${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setAstro(res.data.data.astroDetails.user)
            setAstroDetails(res.data.data.astroDetails);
            setAstroOnlineOffineActivity(res.data.data.astroStatus)
            setCallDetails(res.data.data.callList);
            setVideoCalldetails(res.data.data.videoCallList)
            setvideoCallMissed(res.data.data.videoCallMissed)
            setCallMissedDetails(res.data.data.callMissed);
            setChatDetails(res.data.data.chatList);
            setChatMissedDetails(res.data.data.chatMissed);
        }).catch(err => console.log('Error', err))
    };
    let shortArrayChatDetail
    if (chatDetails) {
        shortArrayChatDetail = chatDetails.rows.slice(0);
        shortArrayChatDetail.length = 4
    }
    let shortArrayChatMissed
    if (chatMissedDetails) {
        shortArrayChatMissed = chatMissedDetails.rows.slice(0);
        shortArrayChatMissed.length = 4
    }
    let shortCallDetails
    if (callDetails) {
        shortCallDetails = callDetails.rows.slice(0);
        shortCallDetails.length = 4
    }
    let shortVideoCallDetails
    if (videoCallList) {
        shortVideoCallDetails = videoCallList.rows.slice(0);
        shortVideoCallDetails.length = 4
    }
    let shortVideoCallMissed
    if (videoCallMissed) {
        shortVideoCallMissed = videoCallMissed.rows.slice(0);
        shortVideoCallMissed.length = 4
    }
    let shortmissedCall
    if (callMissedDetails) {
        shortmissedCall = callMissedDetails.rows.slice(0);
        shortmissedCall.length = 4
    }
    let astroOnOffActivitys
    if (astroOnlineOffineActivity) {
        astroOnOffActivitys = astroOnlineOffineActivity.rows.slice(0);
        astroOnOffActivitys.length = 4
    }
    useEffect(() => {
        getAstrologer();
        setloading(true)
        setTimeout(() => {
            setloading(false)
        }, 1000)
    }, []);
    const style = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px'
    }
    const HandleRedirectChatDetail = (value) => {
        navigate.push({
            pathname: '/admin/astro-chat-details',
            state: {
                chatDetails: value,
                astro: astro.name
            }
        })
    }
    const HandleRedirectChatMissed = (value) => {
        navigate.push({
            pathname: '/admin/astro-chat-missed-details',
            state: {
                chatMissedDetails: value,
                astro: astro.name

            }
        })
    }
    const HandleRedirectCallDetails = (value) => {
        navigate.push({
            pathname: '/admin/astro-call-details',
            state: {
                callDetails: value,
                astro: astro.name

            }
        })
    }

    // <---- Astro Video Call Details---> 

    const HandleRedirectVideoCallDetails = (value) => {
        navigate.push({
            pathname: '/admin/astro-video-call-details',
            state: {
                videoCallList: value,
                astro: astro.name

            }
        })
    }
    const HandleRedirectVideoCallMissedDetails = (value) => {
        navigate.push({
            pathname: '/admin/astro-video-call-missed',
            state: {
                videoCallMissed: value,
                astro: astro.name

            }
        })
    }
    const HandleRedirectCallMissedDetails = (value) => {
        navigate.push({
            pathname: '/admin/astro-call-missed-details',
            state: {
                callMissedDetails: value,
                astro: astro.name

            }
        })
    }
    const HandleRedirectAstroOfflineOnline = (value) => {
        navigate.push({
            pathname: '/admin/astro-astro-online-offline',
            state: {
                astroOnlineOffineActivity: value,
                astro: astro.name

            }
        })
    }

    return (
        <>
            {
                loading ?
                    <div style={{ width: 'fit-content', margin: 'auto', display: 'flex', paddingTop: '200px' }}><div class={styles.clockLoader}></div>
                        <div class={styles.Loading}>Loading...</div>
                    </div>
                    :
                    <div className={styles.astroView}>
                        <div >
                            <h4 style={{ textAlign: 'center' }}>{astro && astro.name}
                                : Account Details</h4>
                            <div style={{ width: '74%' }}>
                            </div>
                        </div>
                        <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <div className="col-md-6" style={{ marginTop: '20px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Experience</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.email}
                                        </div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Mobile</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.mobile}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Chat Id</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.chatId}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Gender</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.gender}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Role</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.role}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Wallet</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.wallet}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Active</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.active ? 'Yes' : 'No'}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Joining Date</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astro && astro.createdAt.substring(0, 10)} </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '20px' }}>
                                <Card body className="border-left-warning card-cust">

                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Experience</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astroDetails && astroDetails.experience} Years </div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Chat Price</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}> ₹{astroDetails && astroDetails.chatPrice}</div></div>

                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Call Price</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>₹{astroDetails && astroDetails.audioCallPrice}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Bank Name</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astroDetails && astroDetails.bankName}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Acc No</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astroDetails && astroDetails.accNumber}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>IFSC Code</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}> {astroDetails && astroDetails.ifsc}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Locality</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astroDetails && astroDetails.locality}</div>
                                    </div>
                                    <div style={style}>
                                        <div style={{ width: '20%' }}><th>Country</th> </div>
                                        <div ><b style={{ width: '90px' }}>:</b></div>
                                        <div style={{ width: '240px' }}>{astroDetails && astroDetails.country}</div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div>
                            <h4 style={{ textAlign: 'center', marginTop: '5px' }}>Chat Details</h4>
                        </div>
                        <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <div className="col-md-6" style={{ marginTop: '30px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div className="row">
                                        <div style={{ hight: '100px', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>

                                                <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', marginLeft: '60px' }} className={styles.button} onClick={() => { HandleRedirectChatDetail(chatDetails, astro) }}><h5>View All Chats</h5></div>
                                                <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                    <i
                                                        className="fa fa-user"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '270px' }}><b  ><h5>Total Chat Connected</h5></b></div>
                                                    <div ><b style={{ width: '10px' }}>:</b></div>
                                                    <div style={{ width: '100px', textAlign: 'center' }}>{chatDetails && chatDetails.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '30px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div className="row">
                                        <div style={{ hight: '100px', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>

                                                <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', marginLeft: '60px' }} onClick={() => { HandleRedirectChatMissed(chatMissedDetails, astro) }} className={styles.button} ><h5> View Chat Missed </h5></div>
                                                <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                    <i
                                                        className="fa fa-user"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '270px' }}><b ><h5>Total Chat Connected</h5></b></div>
                                                    <div ><b style={{ width: '10px' }}>:</b></div>
                                                    <div style={{ width: '100px', textAlign: 'center' }}>{chatMissedDetails && chatMissedDetails.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div>
                            <h4 style={{ textAlign: 'center', marginTop: '10px' }}>Call Details</h4>
                        </div>
                        <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <div className="col-md-6" style={{ marginTop: '30px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div className="row">
                                        <div style={{ hight: '100px', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>

                                                <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', marginLeft: '60px' }} onClick={() => { HandleRedirectCallDetails(callDetails, astro) }} className={styles.button}><h5>View All Call Details</h5></div>
                                                <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                    <i
                                                        className="fa fa-user"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '270px' }}><b  ><h5>Total Call Details</h5></b></div>
                                                    <div ><b style={{ width: '10px' }}>:</b></div>
                                                    <div style={{ width: '100px', textAlign: 'center' }}>{callDetails && callDetails.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '30px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div className="row">
                                        <div style={{ hight: '100px', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>

                                                <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', marginLeft: '60px' }} onClick={() => { HandleRedirectCallMissedDetails(callMissedDetails, astro) }} className={styles.button} ><h5>View All Missed Calls </h5></div>
                                                <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                    <i
                                                        className="fa fa-user"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '270px' }}><b ><h5>Total Missed Call Details</h5></b></div>
                                                    <div ><b style={{ width: '10px' }}>:</b></div>
                                                    <div style={{ width: '100px', textAlign: 'center' }}>{callMissedDetails && callMissedDetails.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>

                        {/* <----Astrologer Video Call Details-----> */}

                        <div>
                            <h4 style={{ textAlign: 'center', marginTop: '10px' }}>Video Call Details</h4>
                        </div>
                        <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <div className="col-md-6" style={{ marginTop: '30px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div className="row">
                                        <div style={{ hight: '100px', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>

                                                <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', marginLeft: '60px' }} onClick={() => { HandleRedirectVideoCallDetails(videoCallList, astro) }} className={styles.button}><h5>View All Call Details</h5></div>
                                                <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                    <i
                                                        className="fa fa-user"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '270px' }}><b  ><h5>Total Call Details</h5></b></div>
                                                    <div ><b style={{ width: '10px' }}>:</b></div>
                                                    <div style={{ width: '100px', textAlign: 'center' }}>{videoCallList && videoCallList.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-6" style={{ marginTop: '30px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div className="row">
                                        <div style={{ hight: '100px', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>

                                                <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', marginLeft: '60px' }} onClick={() => { HandleRedirectVideoCallMissedDetails(videoCallMissed, astro) }} className={styles.button} ><h5>View All Missed Calls </h5></div>
                                                <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                    <i
                                                        className="fa fa-user"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '270px' }}><b ><h5>Total Missed Call Details</h5></b></div>
                                                    <div ><b style={{ width: '10px' }}>:</b></div>
                                                    <div style={{ width: '100px', textAlign: 'center' }}>{videoCallMissed && videoCallMissed.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div>
                            <h4 style={{ textAlign: 'center', marginTop: '10px' }}>Online & Offline Details</h4>
                        </div>
                        <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <div className="col-md-6" style={{ marginTop: '30px' }}>
                                <Card body className="border-left-warning card-cust">
                                    <div className="row">
                                        <div style={{ hight: '100px', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%' }}>

                                                <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', marginLeft: '60px' }} onClick={() => { HandleRedirectAstroOfflineOnline(astroOnlineOffineActivity, astro) }} className={styles.button}><h5>View Online-Offline</h5></div>
                                                <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                    <i
                                                        className="fa fa-user"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '270px' }}><b  ><h5>Total Online/Offline Details </h5></b></div>
                                                    <div ><b style={{ width: '10px' }}>:</b></div>
                                                    <div style={{ width: '100px', textAlign: 'center' }}>{astroOnlineOffineActivity && astroOnlineOffineActivity.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Index;
