import React, { useState } from 'react';
import SideBar from './sidebar/SideBar';
import Content from './content/Content';
import ReactAudioPlayer from 'react-audio-player';

import '../../assets/css/dashboard.style.css';

const Admin = (porps) => {
    const [isOpen, setOpen] = useState(true);
    const toggle = () => setOpen(!isOpen);

    return (
        <div className="App wrapper">
            <SideBar toggle={toggle} isOpen={isOpen} />
            <Content toggle={toggle} isOpen={isOpen} {...porps} />
            {/* <ReactAudioPlayer src="my_audio_file.ogg" autoPlay controls /> */}
        </div>
    );
};

export default Admin;
