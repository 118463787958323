export const ADMIN_LOGIN_START = 'ADMIN_LOGIN_START';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export const ASTRO_SIGNUP_START = 'ASTRO_SIGNUP_START';
export const ASTRO_SIGNUP_SUCCESS = 'ASTRO_SIGNUP_SUCCESS';
export const ASTRO_SIGNUP_FAIL = 'ASTRO_SIGNUP_FAIL';
export const ASTRO_LOGIN_START = 'ASTRO_LOGIN_START';
export const ASTRO_LOGIN_SUCCESS = 'ASTRO_LOGIN_SUCCESS';
export const ASTRO_LOGIN_FAIL = 'ASTRO_LOGIN_FAIL';
export const ASTRO_LOGOUT = 'ASTRO_LOGOUT';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const BASIC_PROFILE_UPDATE_START = 'BASIC_PROFILE_UPDATE_START';
export const BASIC_PROFILE_UPDATE_SUCCESS = 'BASIC_PROFILE_UPDATE_SUCCESS';

export const PROFILE_UPDATE_START = 'PROFILE_UPDATE_START';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const TOKEN_SEND_SUCCESS = 'TOKEN_SEND_SUCCESS';
export const TOKEN_SEND_FAIL = 'TOKEN_SEND_FAIL';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';

export const NOTIFICATION_SEND_START = 'NOTIFICATION_SEND_START';
export const NOTIFICATION_SEND_SUCCESS = 'NOTIFICATION_SEND_SUCCESS';
export const NOTIFICATION_SEND_FAIL = 'NOTIFICATION_SEND_FAIL';
export const CHATROOM_FETCH_SUCCESS = 'CHATROOM_FETCH_SUCCESS ';

export const VASTU_FETCH_SUCCESS = 'VASTU_FETCH_SUCCESS';
export const VASTU_FETCH_FAILED = 'VASTU_FETCH_FAILED';

export const MUHURAT_FETCH_SUCCESS = 'MUHURAT_FETCH_SUCCESS';
export const MUHURAT_FETCH_FAILED = 'MUHURAT_FETCH_FAILED';

export const HANDMADE_REPORT_FETCH_SUCCESS = 'HANDMADE_REPORT_FETCH_SUCCESS';
export const HANDMADE_REPORT_FETCH_FAILED = 'HANDMADE_REPORT_FETCH_FAILED';

export const FETCH_EARNING_START = 'FETCH_EARNING_START';

export const ADMIN_SIGNUP_START = 'ADMIN_SIGNUP_START';
export const ADMIN_SIGNUP_SUCCESS = 'ADMIN_SIGNUP_SUCCESS';
export const ADMIN_SIGNUP_FAIL = 'ADMIN_SIGNUP_FAIL';
export const GET_ADMIN_START = 'GET_ADMIN_START';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
