import {
    ASTRO_LOGIN_START,
    ASTRO_LOGIN_SUCCESS,
    ASTRO_LOGIN_FAIL,
    ASTRO_SIGNUP_START,
    ASTRO_SIGNUP_SUCCESS,
    ASTRO_SIGNUP_FAIL,
    ASTRO_LOGOUT,
    GET_USER_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    loading: false,
    isAuthenticated: localStorage.getItem('token') ? true : false,
    isError: false,
    error: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_SUCCESS:
            return { ...state, data: action.user };
        case ASTRO_LOGIN_START:
            return { ...state, loading: true };
        case ASTRO_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                isError: false,
                errorMessage: '',
                data: action.data,
            };
        case ASTRO_LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                isError: true,
                errorMessage: action.errorMessage,
            };
        case ASTRO_SIGNUP_START:
            return { ...state, loading: true };
        case ASTRO_SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                isError: false,
                errorMessage: '',
                data: action.data,
            };
        case ASTRO_SIGNUP_FAIL:
            return {
                ...state,
                loading: false,
                isError: true,
                errorMessage: action.errorMessage,
            };

        case ASTRO_LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
            };
        default:
            return state;
    }
};
