import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function Checkbox(props) {
    return (
        <>
            <Field name={props.name}>
                {({ field, form }) => (
                    <label className="col-lg-3">
                        <input
                            type="checkbox"
                            {...props}
                            onChange={() => {
                                if (field.value.includes(props.value)) {
                                    const nextValue = field.value.filter(
                                        (value) => value !== props.value
                                    );
                                    form.setFieldValue(props.name, nextValue);
                                } else {
                                    const nextValue = field.value.concat(
                                        props.value
                                    );
                                    form.setFieldValue(props.name, nextValue);
                                }
                            }}
                        />
                        {props.label}
                    </label>
                )}
            </Field>
        </>
    );
}

function Input({ name, type, placeholder, label, ...props }) {
    return (
        <Field name={name}>
            {({ field }) => (
                <div className="col-lg-6">
                    <div className="form-group">
                        <div className="row">
                            {label ? (
                                <label className="col-lg-4 col-form-label">
                                    {label}
                                </label>
                            ) : (
                                ''
                            )}
                            <div className="col-lg-8">
                                <input
                                    {...props}
                                    {...field}
                                    type={type}
                                    placeholder={
                                        placeholder ? placeholder : label
                                    }
                                    className="form-control"
                                />
                                <ErrorMessage
                                    style={{ color: 'red' }}
                                    component="div"
                                    name={name}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Field>
    );
}

const validationSchema = Yup.object().shape({
    locality: Yup.string()
        .required('Required')
        .min(3, 'Please enter you locality.'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    pinCode: Yup.number().required('Required'),
    chatPrice: Yup.number().required('Required'),
    reportPrice: Yup.number().required('Required'),
    audioCallPrice: Yup.number().required('Required'),
    experience: Yup.number().required('Required'),
    bankName: Yup.string().required('Required'),
    ifsc: Yup.string().required('Required'),
    accNumber: Yup.number().required('Required'),
    about: Yup.string()
        .required('Required')
        .min(50, 'Descrption must be 50 or more than 50 character.'),
    services: Yup.array()
        .of(Yup.string())
        .required('Atleast select one of the services.'),
    languages: Yup.array().required('Atleast select one of the language.'),
});

function Form(props) {
    const initialValues = {
        locality: '',
        city: '',
        state: '',
        country: 'India',
        pinCode: '',
        chatPrice: '',
        audioCallPrice: '',
        reportPrice: '',
        experience: '',
        bankName: '',
        ifsc: '',
        accNumber: '',
        about: '',
        services: [],
        languages: [],
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                props.handleFormSubmit(values);
            }}
            validationSchema={validationSchema}
        >
            {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                    <div className="row row-bg-2">
                        <div className="col-lg-12">
                            <h5>Address</h5>
                        </div>

                        {/* Address details */}
                        <Input
                            label="Address/Locality"
                            type="text"
                            name="locality"
                        />
                        <Input label="City" type="text" name="city" />
                        <Input label="State" type="text" name="state" />
                        <Input label="Country" type="text" name="country" />
                        <Input label="Pincode" type="number" name="pinCode" />
                        <div className="col-lg-6"></div>
                    </div>
                    <div className="row row-bg-3">
                        <div className="col-lg-12">
                            <h5>Price Information</h5>
                        </div>
                        {/* Rate/Price details */}
                        <Input
                            label="Chat Price"
                            type="number"
                            name="chatPrice"
                        />
                        <Input
                            label="Call Price"
                            type="number"
                            name="audioCallPrice"
                        />
                        <Input
                            label="Report Price"
                            type="number"
                            name="reportPrice"
                        />
                        <Input
                            label="Experience"
                            type="number"
                            name="experience"
                        />
                    </div>
                    <div className="row row-bg-4">
                        <div className="col-lg-12">
                            <h5>Services</h5>
                        </div>
                        {/* Service/language details */}
                        <div className="col-lg-12">
                            <h6>Select Services with Expertise</h6>
                            <div className="checkbox">
                                <Checkbox
                                    label="Vedic Astrology"
                                    name="services"
                                    value="Vedic Astrology"
                                />
                                <Checkbox
                                    label="Tarot Card Reading"
                                    name="services"
                                    value="Tarrot Card Reading"
                                />
                                <Checkbox
                                    label="Numerology"
                                    name="services"
                                    value="Numerology"
                                />
                                <Checkbox
                                    label="Vaastu"
                                    name="services"
                                    value="Vaastu"
                                />

                                <Checkbox
                                    label="Prashna Kundali"
                                    name="services"
                                    value="Prashna Kundali"
                                />
                                <Checkbox
                                    label="Fengshui"
                                    name="services"
                                    value="Fengshui"
                                />
                                <Checkbox
                                    label="Kunderkand"
                                    name="services"
                                    value="Kunderkand"
                                />
                                <Checkbox
                                    label="Ramayan"
                                    name="services"
                                    value="Ramayan"
                                />
                                <Checkbox
                                    label="Palmist"
                                    name="services"
                                    value="Palmist"
                                />
                                <Checkbox
                                    label="Karmkand"
                                    name="services"
                                    value="Karmkand"
                                />
                                <Checkbox
                                    label="Spiritual Healer"
                                    name="services"
                                    value="Spiritual Healer"
                                />
                                <Checkbox
                                    label="Horoscopes Analysis"
                                    name="services"
                                    value="Horoscopes Analysis"
                                />
                            </div>
                            <ErrorMessage
                                style={{ color: 'red' }}
                                component="div"
                                name="services"
                            />

                            <h6>Languages </h6>
                            <div className="checkbox">
                                <Checkbox
                                    label="English"
                                    name="languages"
                                    value="English"
                                />
                                <Checkbox
                                    label="Hindi"
                                    name="languages"
                                    value="Hindi"
                                />
                                <Checkbox
                                    label="Gujarati"
                                    name="languages"
                                    value="Gujrati"
                                />
                                <Checkbox
                                    label="Marathi"
                                    name="languages"
                                    value="Marathi"
                                />
                                <Checkbox
                                    label="Punjabi"
                                    name="languages"
                                    value="Punjabi"
                                />
                                <Checkbox
                                    label="Bengoli"
                                    name="languages"
                                    value="Bengoli"
                                />
                                <Checkbox
                                    label="Telugu"
                                    name="languages"
                                    value="Telgu"
                                />
                                <Checkbox
                                    label="Tamil"
                                    name="languages"
                                    value="Tamil"
                                />
                            </div>
                            <ErrorMessage
                                style={{ color: 'red' }}
                                component="div"
                                name="languages"
                            />
                        </div>
                    </div>
                    <div className="row row-bg-5">
                        <div className="col-lg-12">
                            <h5>Account Information</h5>
                        </div>
                        {/* Bank details */}
                        <Input label="Bank Name" type="text" name="bankName" />
                        <Input
                            label="Account Number"
                            type="number"
                            name="accNumber"
                        />
                        <Input label="Bank IFSC" type="text" name="ifsc" />

                        {/* About */}
                        <div className="col-lg-12">
                            <div className="form-group">
                                <div className="row">
                                    <label className="col-lg-1 col-form-label">
                                        About
                                    </label>
                                    <div className="col-lg-8">
                                        <Field
                                            as="textarea"
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="3"
                                            name="about"
                                        ></Field>
                                        <ErrorMessage
                                            style={{ color: 'red' }}
                                            component="div"
                                            name="about"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="submit-btn">
                        <button type="submit" className="btn btn-info mb-2">
                            Update
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default Form;
