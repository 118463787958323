import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-router-dom';
import axios from 'axios';
import date from 'date-and-time';
import TableScrollbar from 'react-table-scrollbar';

const url = process.env.REACT_APP_BASE_API;

const ChatHistroy = () => {
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const pattern = date.compile('D MMM YYYY hh:mm A');

    useEffect(() => {
        getChatHistroy();
    }, []);
    const getChatHistroy = async () => {
        const res = await axios.get(url + '/chat/chatRooms', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setChatHistory([...chatHistory, ...res.data.chatRooms]);
        }
    };

    return (
        <div className="dash-wapp">
            <h1 className="heading-tit">Chat History </h1>

            <div className="">
                <div className="">
                    <div className="" style={{ height: '90vh' }}>
                        <TableScrollbar>
                            <table
                                class="table table-striped table-bordered"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '3px',
                                    backgroundColor: 'white',
                                }}
                            >
                                <thead>
                                    <tr
                                        style={{
                                            boxShadow:
                                                '0.5px 0.5px 5px 1px grey inset',
                                        }}
                                    >
                                        <th>SNo.</th>
                                        <th>Chat Room Id</th>
                                        <th>Date &amp; Time</th>
                                        <th>User Name</th>
                                        <th>Per Min Charges</th>
                                        <th>Chat Duration</th>
                                        <th>Amount Charged</th>
                                        <th>Your Earning</th>
                                    </tr>
                                </thead>
                                <tbody
                                    style={{
                                        overflow: 'scrollY',
                                    }}
                                >
                                    {chatHistory && chatHistory.length > 0 ? (
                                        chatHistory.map((data, index) => {
                                            return (
                                                <tr key={data.id}>
                                                    <td>{index+1}</td>
                                                    <td>{data.chatRoomId}</td>
                                                    <td>
                                                        {date.format(
                                                            new Date(
                                                                data.startTime
                                                            ),
                                                            pattern
                                                        )}
                                                    </td>
                                                    <td>{data?.user?.name}</td>
                                                    <td>
                                                        {data.chatRate}{' '}
                                                        <i
                                                            className="fa fa-inr"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </td>
                                                    <td>
                                                        {data?.chatDuration} Sec
                                                    </td>
                                                    <td>
                                                        {data?.amountCharged}{' '}
                                                        <i
                                                            className="fa fa-inr"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </td>
                                                    <td>
                                                        {(data?.amountCharged *
                                                            50) /
                                                            100}{' '}
                                                        <i
                                                            className="fa fa-inr"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <p>There isn't present any data yet.</p>
                                    )}
                                </tbody>
                            </table>
                        </TableScrollbar>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatHistroy;

// {
//     chatHistory && chatHistory.length > 0
//         ? chatHistory.map((data) => {
//               (() => {
//                   if (totalEarning[data.userId]) {
//                       totalEarning[data.userId] += parseInt(
//                           data.amountCharged
//                       );
//                   } else {
//                       totalEarning[data.userId] = parseInt(
//                           data.amountCharged
//                       );
//                   }
//                   return totalEarning;
//               })();

//               return <tr key={data.id} style={{ display: 'hidden' }}></tr>;
//           })
//         : console.log('NO Earn');
//     // console.log(totalEarning);
// }
