import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert } from 'reactstrap';

const url = process.env.REACT_APP_BASE_API;

const InputField = ({ name, formik, type, placeholder, label, ...rest }) => {
    return (
        <div className="col-lg-6">
            <div className="form-group">
                <div className="row">
                    {label ? (
                        <label className="col-lg-4 col-form-label">
                            {label}
                        </label>
                    ) : (
                        ''
                    )}
                    <div className="col-lg-8">
                        <input
                            type={type}
                            name={name}
                            placeholder={placeholder ? placeholder : label}
                            className="form-control"
                            {...rest}
                            value={formik.values[name]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.errors[name] && formik.touched[name] && (
                            <div
                                style={{
                                    color: 'rgb(235, 54,54)',
                                    fontSize: '14px',
                                }}
                            >
                                {formik.errors[name]}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const UpdatePasswordForm = () => {
    const [visible, setVisible] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const onDismiss = () => setVisible(false);

    const updatePassword = (values) => {
        fetch(`${url}/account/updateMyPassword`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsSuccess(true);
                    setMessage('Successfully changed the password');
                    formik.resetForm();
                },
                (error) => {
                    console.log(error);
                    setIsError(true);
                    setError('Something went wrong. Try again');
                }
            );
    };

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        onSubmit: (values) => {
            updatePassword(values);
        },
        validationSchema: () =>
            Yup.object().shape({
                currentPassword: Yup.string()
                    .required('Required')
                    .min(8, 'Password must have more than 8 characters'),
                newPassword: Yup.string()
                    .required('Required')
                    .min(8, 'Password must have more than 8 characters'),
                confirmPassword: Yup.string()
                    .required('Required')
                    .oneOf(
                        [Yup.ref('newPassword')],
                        'Does not match with new password'
                    ),
            }),
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    {isSuccess && (
                        <Alert color="info" isOpen={visible} toggle={onDismiss}>
                            {message}
                        </Alert>
                    )}
                </div>
            </div>
            <div className="row">
                <InputField
                    label="Current Password"
                    type="password"
                    name="currentPassword"
                    formik={formik}
                />
                <div className="col-lg-6"></div>
                <InputField
                    label="New Password"
                    type="password"
                    name="newPassword"
                    formik={formik}
                />
                <div className="col-lg-6"></div>
                <InputField
                    label="Retype Password"
                    type="password"
                    name="confirmPassword"
                    formik={formik}
                />
            </div>
            <div className="submit-btn">
                <button type="submit" className="btn btn-info mb-2">
                    Update
                </button>
            </div>
        </form>
    );
};

export default UpdatePasswordForm;
