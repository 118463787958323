import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

const url = process.env.REACT_APP_BASE_API


const Index = () => {
    const [display, setDisplay] = useState(false);
    const [msg, setMsg] = useState("");
    const [err, setErr] = useState("");

    const SEO = (value) => {
        axios.post(url + "/admin/astrologer-seo", value, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            setErr(res.data.status)
            if (res.data.status == 'success') {
                setDisplay(true);
                setMsg(res.data.msg)
                setTimeout(() => {
                    setDisplay(false)
                    setMsg('')
                }, 3000);
            } else if (res.data.status == 'failed') {
                setDisplay(true);
                setMsg(res.data.msg)
                setTimeout(() => {
                    setDisplay(false)
                    setMsg('')
                }, 3000);
            }
        }).catch((err) => {
            console.log('Error ::::', err)
        })
    };

    const inputError = (formik, input) => {
        return (
            formik.errors[input] &&
            formik.touched[input] && (
                <div
                    style={{
                        color: "rgb(235, 54,54)",
                        fontSize: "12px",
                        marginLeft: '5px',
                        fontWeight: 'bold'
                    }}
                >
                    {formik.errors[input]}
                </div>
            )
        );
    };

    const formik = useFormik({
        initialValues: {
            astroId: "",
            title: "",
            metaImage: "",
            metaUrl: "",
            metaDescription: "",
            metaKeyword: "",
        },
        onSubmit: (values) => {
            SEO(values)
        },

        validationSchema: () =>
            Yup.object().shape({
                astroId: Yup.string()
                    .required("Required!"),
                title: Yup.string()
                    .required("Required!"),
                metaImage: Yup.string()
                    .required("Required!"),
                metaUrl: Yup.string()
                    .required("Required!"),
                metaDescription: Yup.string()
                    .required("Required!"),
                metaKeyword: Yup.string()
                    .required("Required!"),

            })
    })



    return (
        <>
            <div>
                <h3 style={{textAlign: 'center', borderBottom: '2px solid gray', width: '30vw', marginLeft: '27vw',paddingBottom: '10px'}}>Add Astrologer SEO </h3>
                <div style={{ width: '30vw', height: '74vh',marginLeft: '27vw',boxShadow: '0px 10px 10px 10px rgba(0, 0, 0, 0.25)', }}>

                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        {display && <div
                            style={{backgroundColor: `${err == 'failed' ? '#ff4646' : '#008e00ab'}`,borderRadius: '10px', color: 'white', margin: '10px 0px', padding: '10px', width: 'fit-content', textAlign: 'center' }}>{msg}</div>}

                        <label style={{ marginLeft: '20px', fontWeight: 600 }}>Astrologer Id:</label>
                        <br />
                        <input
                            type="text"
                            placeholder="Enter Astrologer Id"
                            name="astroId"
                            value={formik.values.astroId}
                            onChange={formik.handleChange}
                            style={{
                                width: '28vw',
                                padding: '6px',
                                marginLeft: '10px',
                                display: 'inlineblock',
                                border: '1px solid #ccc',
                                boxSizing: "border-box",
                                borderRadius: '10px',
                                textAlign: 'center'
                            }} />
                        {inputError(formik, "astroId")}
                        <br />
                        <label style={{ marginLeft: '20px', fontWeight: 600}}>Title:</label>
                        <br />
                        <input
                            type="text"
                            placeholder="Enter Title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            style={{
                                width: '28vw',
                                padding: '6px',
                                marginLeft: '10px',
                                display: 'inlineblock',
                                border: '1px solid #ccc',
                                boxSizing: "border-box",
                                borderRadius: '10px',
                                textAlign: 'center'
                            }} />
                        {inputError(formik, "title")}
                        <br />
                        <label style={{ marginLeft: '20px', fontWeight: 600 }}>Meta Keyword: </label>
                        <br />
                        <input
                            type="text"
                            placeholder="Enter Meta Keyword"
                            name="metaKeyword"
                            value={formik.values.metaKeyword}
                            onChange={formik.handleChange}
                            style={{
                                width: '28vw',
                                padding: '6px',
                                marginLeft: '10px',
                                display: 'inlineblock',
                                border: '1px solid #ccc',
                                boxSizing: "border-box",
                                borderRadius: '10px',
                                textAlign: 'center'
                            }} />
                        {inputError(formik, "metaKeyword")}
                        <br />
                        <label style={{ marginLeft: '20px', fontWeight: 600}}>Meta URL:</label>
                        <br />
                        <input
                            type="text"
                            placeholder="Enter Meta URL"
                            name="metaUrl"
                            value={formik.values.metaUrl}
                            onChange={formik.handleChange}
                            style={{
                                width: '28vw',
                                padding: '6px',
                                marginLeft: '10px',
                                display: 'inlineblock',
                                border: '1px solid #ccc',
                                boxSizing: "border-box",
                                borderRadius: '10px',
                                textAlign: 'center'
                            }} />
                        {inputError(formik, "metaUrl")}
                        <br />
                        <label style={{ marginLeft: '20px', fontWeight: 600 }}>Meta Profile Image:</label>
                        <br />
                        <input
                            type="text"
                            placeholder="Enter Meta URL"
                            name="metaImage"
                            value={formik.values.metaImage}
                            onChange={formik.handleChange}
                            style={{
                                width: '28vw',
                                padding: '6px',
                                marginLeft: '10px',
                                display: 'inlineblock',
                                border: '1px solid #ccc',
                                boxSizing: "border-box",
                                borderRadius: '10px',
                                textAlign: 'center'
                            }}/>
                        {inputError(formik, "metaImage")}
                        <br />
                        <label style={{ marginLeft: '20px', fontWeight: 600}}>Meta Description:</label>
                        <br />
                        <input
                            type="text"
                            placeholder="Enter Meta Description"
                            name="metaDescription"
                            value={formik.values.metaDescription}
                            onChange={formik.handleChange}
                            style={{
                                width: '28vw',
                                padding: '6px',
                                marginLeft: '10px',
                                display: 'inlineblock',
                                border: '1px solid #ccc',
                                boxSizing: "border-box",
                                borderRadius: '10px',
                                textAlign: 'center'
                            }} />
                        {inputError(formik, "metaDescription")}

                        <br />
                        <button onClick={formik.handleSubmit} type="submit" 
                        style={{
                            width: '10vw',
                            padding: '10px',
                            margin: 'auto',
                            marginTop: '1vh',
                            display: 'inlineblock',
                            border: '1px solid #ccc',
                            boxSizing: "border-box",
                            borderRadius: '10px',
                            backgroundColor:'#FF9900',
                            color: 'white',
                            fontWeight: 600,
                            marginLeft: '10vw',
                            marginTop: '3vh'
                        }}
                        >Submit</button>
                    </form>
                </div>

            </div>
        </>
    );
};

export default Index;