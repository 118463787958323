import React from 'react';

const Message = ({ message: { type, text, index } }) => {
    let isSentByCurrentUser = false;
    if (type === 'replies') {
        isSentByCurrentUser = true;
    }

    return isSentByCurrentUser ? (
        <li className="replies">
            <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
            <p>{text}</p>
        </li>
    ) : (
        <li className="sent">
            <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" />
            <p>{text}</p>
        </li>
    );
};

export default Message;
