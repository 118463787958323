import React, { useState, useEffect } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
const url = process.env.REACT_APP_BASE_API;

const Earning = (props) => {
    const { state } = useLocation();
    const [oneMonthCallEarn, setOneCallMonthEarn] = useState();
    const [oneMonthChatEarn, setOneChatMonthEarn] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState('');
    const [totalChatEarnings, setTotalChatEarnings] = useState(0);
    const [totalCallEarnings, setTotalCallEarnings] = useState(0);
    const id = state.id;
    const getOneMonthEarn = async () => {
        const res = await axios.patch(`${url}/earn/oneMonthEarn/${id}`)
        if (res.data.status === 'success') {
            setOneCallMonthEarn(res.data.data[0].callData[0].totalCall);
            setOneChatMonthEarn(res.data.data[0].chatData[0].total);
        }
    };

    useEffect(() => {
        getOneMonthEarn();
    }, []);

    async function getEarning() {
        try {
            if (startDate !== '' && endDate !== '' && startDate > endDate) {
                alert('Start Date should be before End Date');
                setEndDate('');
                return;
            } else if (!startDate) {
                alert('Please fill start Date');
                return;
            } else if (startDate === '' && endDate !== '') {
                alert('Please enter start date');
                return;
            } else if (startDate !== '' && endDate === '') {
                alert('Please enter end date');
                return;
            } else {
                axios({
                    method: 'get',
                    url: `${url}/astro/total-earning/earnings/${startDate}/${endDate}`,
                    headers: {
                        Authorization:
                            'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                }).then((response) => {
                    setTotalChatEarnings(
                        response.data.data[0].chatData[0].total
                    );
                    setTotalCallEarnings(
                        response.data.data[0].callData[0].totalCall
                    );
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }
    // useEffect(() => {

    // }, []);
    return (
        <>
            <h1 className="heading-tit">Your Earning </h1>

            <div
                className="row"
                style={{
                    boxShadow: '1px 3px 10px 1px',
                    borderRadius: '2px',
                    padding: '10px',
                    width: '100%',
                    margin: 'auto',
                    // display: 'inline',
                }}
            >
                <div className="col-sm-6 my-1">
                    <Label htmlFor="startDate">From</Label>
                    <Input
                        type="date"
                        name="date"
                        id="startDate"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                    />
                </div>
                <div className="col-sm-6 my-1">
                    <Label htmlFor="endDate">To</Label>
                    <Input
                        type="date"
                        name="date"
                        id="endDate"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                    />
                </div>

                <Button
                    className=""
                    onClick={getEarning}
                    style={{
                        borderRadius: '3px',
                        margin: '2% auto',
                        color: 'black',
                        backgroundColor: '#ffc107',
                    }}
                >
                    Submit
                </Button>
            </div>

            <div className="" style={{}}>
                <table
                    className="table table-striped table-bordered earningTable"
                    style={{
                        borderRadius: '3px',
                        boxShadow: '1px 3px 10px 1px',
                        margin: '20px auto',
                        width: '100%',
                    }}

                >
                    <thead>
                        <tr>
                            <th className='th'></th>
                            <th>{((totalCallEarnings * 50) / 100).toFixed(2) > 0 ? startDate + " to " + endDate + " Earning": "Recent One Month Earning"}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th className='th'>Chat Earning</th>
                            <td>
                                {((totalChatEarnings * 50) / 100).toFixed(2) > 0 ? ((totalChatEarnings * 50) / 100).toFixed(2) : ((oneMonthChatEarn * 50) / 100).toFixed(2) >= 0 ? ((oneMonthChatEarn * 50) / 100).toFixed(2) : 0.00}{' '}
                                <i className="fa fa-inr" aria-hidden="true"></i>
                            </td>
                        </tr>
                        <tr>
                            <th className='th'>Call Earning</th>
                            <td>
                                {((totalCallEarnings * 50) / 100).toFixed(2) > 0 ? ((totalCallEarnings * 50) / 100).toFixed(2) : ((oneMonthCallEarn * 50) / 100).toFixed(2) >= 0 ? ((oneMonthCallEarn * 50) / 100).toFixed(2) : 0.00}{' '}
                                <i className="fa fa-inr" aria-hidden="true"></i>
                            </td>
                        </tr>
                        <tr>
                            <th className='th'>Total Earning</th>
                            <th>
                                {(
                                    (totalChatEarnings * 50) / 100 +
                                    (totalCallEarnings * 50) / 100
                                ).toFixed(2) > 0 ? (
                                    (totalChatEarnings * 50) / 100 +
                                    (totalCallEarnings * 50) / 100
                                ).toFixed(2) : (
                                    (oneMonthChatEarn * 50) / 100 +
                                    (oneMonthCallEarn * 50) / 100
                                ).toFixed(2) >= 0 ? (
                                    (oneMonthChatEarn * 50) / 100 +
                                    (oneMonthCallEarn * 50) / 100
                                ).toFixed(2) : 0.00}{' '}
                                <i className="fa fa-inr" aria-hidden="true"></i>
                            </th>
                        </tr>
                    </tbody>
                </table>

                <div>
                    <Button
                        style={{
                            borderRadius: '3px',
                            margin: '2% auto',
                            color: 'black',
                            backgroundColor: '#ffc107',
                        }}
                    >
                        <a href="mailto:info@Jyotirvid.in">
                            Request for Withdraw{' '}
                            <i className="fa fa-angle-right"></i>
                            <i className="fa fa-angle-right"></i>
                        </a>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Earning;
