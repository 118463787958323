import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// import MyTimer from "../../Talkastrologer/Timer";
const Popup = (props) => {
    const { open, className, onCancel, message, toggle, dismiss, time } = props;
    const [backdrop] = useState(true);
    const [keyboard] = useState(true);

    return (
        <div>
            <Modal
                isOpen={open}
                className={className}
                backdrop={backdrop}
                keyboard={keyboard}
                centered
            >
                <ModalHeader>Information</ModalHeader>
                <ModalBody>
                    <div className="chat-info-popup">{message}</div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default Popup;
