import React from 'react';

const InfoBar = ({ name }) => {
    return (
        <div className="contact-profile">
            <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
            <p>{name}</p>
        </div>
    );
};

export default InfoBar;
