import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import decoder from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogout, getUser } from './store/actions/adminAuth';

function PrivateRoute1({ component: Component, ...rest }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    useEffect(() => {
        if (!user.data) {
            dispatch(getUser());
        }
    }, []);
    const checkAuth = () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const { exp } = decoder(token);
            if (exp < new Date().getTime() / 1000) {
                dispatch(adminLogout());
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;
        }

        return true;
    };

    return (
        <Route
            {...rest}
            render={(props) => {
                return checkAuth() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/admin/login',
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
}

export default PrivateRoute1;
