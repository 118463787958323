import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';

import { getHandmadeReport } from '../../../../store/actions/services';

const Muhurat = (props) => {
    const dispatch = useDispatch();
    const reports = useSelector((state) => state.services.kundli);

    useEffect(() => {
        dispatch(getHandmadeReport());
    }, []);

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Desc</th>
                        <th>Image</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {reports &&
                        reports.map((report, index) => (
                            <tr key={report.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{report.title}</td>
                                <td>{report.description}</td>
                                <td style={{ width: '50px', height: '50px' }}>
                                    <img
                                        src={require(`../../../../assets/images/kundli/${report.image}`)}
                                        alt={report.title}
                                    />
                                </td>
                                <td>{report.price}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
};

export default Muhurat;
