import axios from 'axios';
import history from '../../helpers/history';
import {
    ADMIN_LOGIN_START,
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGIN_FAIL,
    ADMIN_LOGOUT,
    GET_USER_START,
    GET_USER_SUCCESS
} from './types';

const url = process.env.REACT_APP_BASE_API;

export { adminLogin, adminLogout, getUser };

function getUser() {
    return async (dispatch) => {
        dispatch({ type: GET_USER_START });
        try {
            const { data } = await axios.get(`${url}/account/me`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (data.status === 'success') {
                localStorage.setItem('chatId', data.user.chatId);
                dispatch({ type: GET_USER_SUCCESS, user: data.user });
            }
        } catch (error) {
            console.log(error);
        }
    };
}

function adminLogin(formValues) {
    return async (dispatch) => {
        dispatch({ type: ADMIN_LOGIN_START });
        try {
            formValues.role = 'admin'
            const response = await axios.post(
                `${url}/account/login`,
                formValues
            );
            if (response.data.success) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('admin', true);
                dispatch({
                    type: ADMIN_LOGIN_SUCCESS,
                    message: 'Login Successfully',
                });
                history.push('/admin/dashboard');
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                dispatch({
                    type: ADMIN_LOGIN_FAIL,
                    message: err.response.data.message,
                });
            } else if (err.request) {
                dispatch({
                    type: ADMIN_LOGIN_FAIL,
                    message: 'Something went wrong',
                });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(err.message);
            }
            console.log(err.config);
        }
    };
}
function adminLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('admin');
    history.push('/admin/login');
    return { type: ADMIN_LOGOUT };
}
