import React, { useState, useEffect, Fragment } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import styles from '../../Styles/Admin.module.css'
import moment from 'moment'


const url = process.env.REACT_APP_BASE_API

const Index = () => {
    const router = useHistory()
    const [data, setData] = useState();
    const HandleRedirect = (value) => {
        router.push({
            pathname: '/admin/astro-profile',
            state: {
                data: value.id
            }
        })
    }
    const getAstrologerData = async () => {
        const res = await axios.get(url + '/chat/user', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setData(res.data.chatRooms);
        }
    };
    useEffect(() => {
        getAstrologerData();
    }, []);
    return (
        <div>
            <h1 className="heading-tit" style={{ textAlign: 'center', padding: '2px' }}>Astrologers Profile</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={styles.astroView}>
                    <table
                        class="table table-striped table-bordered" l
                        style={{
                            border: '1px solid gray',
                            borderRadius: '3px',
                            backgroundColor: 'white',
                        }}>
                        <thead className={styles.astroView1}>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Joining Date</th>
                                <th style={{color: 'orange'}}>Profile</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 ? data.map((data, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.name}</td>
                                    <td>{data.email}</td>
                                    <td>{data.mobile}</td>
                                    <td>{moment(data.createdAt).format('llll')}</td>
                                    <td className={styles.button} onClick={() => { HandleRedirect(data) }}>View Profile</td>
                                </tr>
                            }):(
                                <p>*There isn't present any data yet.</p>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Index;
