import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { updateStatus } from '../../store/actions/astroProfile';

import Dialog from './Dialog';

const IdleTimerContainer = () => {
    const [status, setStatus] = useState('offline');
    const [dialog, setDialog] = useState(false);
    const dispatch = useDispatch();
    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);

    const message =
        'You have been inactive for the last 15 min. Your status will be set to offline in 3 minutes.';

    const onIdle = () => {
        console.log('User is Idle');
        setDialog(true);
        // sessionTimeoutRef.current = setTimeout(changeStatus, 2 * 1000);
    };

    const changeStatus = () => {
        console.log(
            'Your status has been changed as you are not active in last 20 min'
        );
    };

    const stayActive = () => {
        setDialog(false);
        clearTimeout(sessionTimeoutRef.current);
    };

    const goOffline = () => {
        setDialog(false);
        clearTimeout(sessionTimeoutRef.current);
    };
    // console.log("sattus", status);
    return (
        <>
            <IdleTimer
                ref={idleTimerRef}
                timeout={10 * 60 * 1000}
                onIdle={onIdle}
            />
            {dialog && (dispatch(updateStatus(status)))}
            {/* {dialog && (
                <Dialog
                    open={dialog}
                    message={message}
                    stayActive={stayActive}
                    goOffline={goOffline}
                />
            )} */}
        </>
    );
};

export default IdleTimerContainer;
