import { CometChat } from '@cometchat-pro/chat';

const APPID = process.env.REACT_APP_COMET_CHAT_APP_ID;
const promise = new Promise((resolve, reject) => {
    let cometChatSettings = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion('us')
        .build();

    CometChat.init(APPID, cometChatSettings).then(
        () => {
            console.log('Initialization completed successfully');
            return resolve();
        },
        (error) => {
            console.log('Initialization failed with error:', error);
            //Check the reason for error and take appropriate action.
            reject(
                Error('Error occure during initilazation of Comet Chat api')
            );
        }
    );
});
export default promise;
