import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    Navbar,
    Button,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import { signOut } from '../../../store/actions/astroAuth';

const url = process.env.REACT_APP_BASE_URL;

export default (props) => {
    const [isOpen, setOpen] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);

    const toggle = () => setOpen(!isOpen);
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const toogleNotification = () =>
        setNotificationOpen((prevState) => !prevState);

    return (
        <Navbar
            color="light"
            light
            className="navbar shadow-sm p-3 mb-5 bg-white rounded"
            expand="md"
        >
            <Button color="info" onClick={props.toggle}>
                <FontAwesomeIcon icon={faAlignLeft} />
            </Button>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink tag={Link} to={'/astrologer/earning/'}>
                            Total earnings {user ? user.wallet : 0}{' '}
                            <i className="fa fa-inr"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to={'/astrologer/menu-3'}>
                            <i className="fa fa-envelope fa-fw"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink>
                            <Dropdown
                                // className="drop"
                                direction="down"
                                isOpen={dropdownOpen}
                                toggle={toggleDropdown}
                            >
                                <DropdownToggle caret>
                                    <img
                                        className="rounded-circle rounded-circle-avt"
                                        alt="User"
                                        src={
                                            user
                                                ? `${user.image}`
                                                : ''
                                        }
                                    />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        tag={Link}
                                        to={'/astrologer/dashboard/'}
                                    >
                                        <img
                                            className="rounded-circle rounded-circle-avt rounded-circle-avts rounded-circle-avts-ct"
                                            alt="User"
                                            // src={img1}
                                            src={
                                                user
                                                    ? `${user.image}`
                                                    : ''
                                            }
                                            style={{
                                                display: 'block',
                                                margin: '2px auto',
                                            }}
                                        />
                                        <span className="nm">
                                            {user
                                                ? user.name
                                                      .split(' ')
                                                      .slice(0, 10)
                                                      .join(' ')
                                                : 'user'}
                                        </span>
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    <DropdownItem
                                        tag={Link}
                                        to={'/astrologer/profile'}
                                    >
                                        Profile
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => dispatch(signOut())}
                                    >
                                        <i className="fa fa-sign-out-alt"></i>
                                        logout
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};
