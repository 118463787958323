import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Admin/Styles/Admin.module.css'

const url = process.env.REACT_APP_BASE_API;

const FreeKundaliUsers = () => {
    const [kundliHistory, setKundliHistory] = useState([]);


    const getUsers = async () => {
        const res = await axios.get(url + '/admin/free-matchmaking-users', {
            headers: {
                Accept: "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            console.log('bhh', res)
            setKundliHistory([...kundliHistory, ...res.data.data.rows]);
        }

    };
    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div>
            <div className="dash-wapp">
                <h1 className="heading-tit" style={{ textAlign: 'center', marginTop: '20px' }}>Free Match Making Users({kundliHistory.length})</h1>
                <div className="" style={{ height: '90vh' }}>
                    <div className={styles.user}>
                        <table
                            class="table table-striped table-bordered"
                            style={{
                                border: '1px solid gray',
                                borderRadius: '3px',
                                backgroundColor: 'white',
                            }}
                        >
                            <thead >
                                <tr

                                >
                                    <th>S.No.</th>
                                    <th>Boy Name</th>
                                    {/* <th>Boy DOB</th>
                                    <th>Boy TOB</th> */}
                                    <th>Girl Name</th>
                                    {/* <th>Girl DOB</th>
                                    <th>Girl TOB</th> */}
                                    <th>Match Making Date</th>
                                    <th>User Details</th>
                                </tr>
                            </thead >
                            <tbody className={styles.user}

                            >
                                {kundliHistory && kundliHistory.length > 0 ? (
                                    kundliHistory.map((data, index) => {
                                        let d1 = data.created_at.split('T')
                                        let d2;
                                        d2 = d1[1].split('.000Z')[0]
                                        return (
                                            <tr key={data.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {data.bname}
                                                </td>
                                                {/* <td>
                                                    {data.bdob ? data.bdob.split('T')[0] : '--'}
                                                </td>
                                                <td>
                                                    {data.btob}
                                                </td> */}
                                                <td>{data.gname}</td>
                                                {/* <td>
                                                    {data.gdob ? data.gdob.split('T')[0] : '--'}
                                                </td>
                                                <td>
                                                    {data.gtob}
                                                </td> */}
                                                <td>
                                                    {d2 + ' ' + d1[0]}</td>
                                                <td>
                                                    {data.user ? data.user : '--'}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <p>*There isn't present any data yet.</p>
                                )}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default FreeKundaliUsers