import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'




function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    useEffect(() => {
    
        return () => {
            // if(localStorage.getItem(user-info)){
            //     history.push("/superAdminDashboard");
            // }
        };
    }, [])
    async function login(){
        console.warn(email,password)
        let item = {email, password};
        let result = await fetch("http://localhost:3010/api/account/admin/logIn",{
            method: 'POST',
            header: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(item)
        })
        result = await result.json();
        // localStorage.setItem(JSON.stringify(result));
        history.push('/superAdminDashboard');

    }

return (
    <div>
        <h1>Log in Super Admin</h1>
        <div className='col-sm-6 offset-sm-3'>
            <input type='text' placeholder='Enter Email' onChange={(e)=>setEmail(e.target.value)} className='form-control' />
                <br />
                <input type='password' placeholder='Enter Password' onChange={(e)=>setPassword(e.target.value)} className='form-control' />
                <br />
                <button onClick={login} className='btn btn-primary'>Login</button>
            </div>
    </div>
);
}

export default Login;