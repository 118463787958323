import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import NavBar from '../Navbar/Navbar';
import { Switch, Route, Redirect } from 'react-router-dom';

import Dashboard from '../Dashboard';
import Chat from '../Chat';
import Profile from '../profile';
import ChatHistory from '../ChatHistory';
import CallHistory from '../CallHistory';
import Earning from '../Earning';
import Menu3 from '../Menu-3';
import AstrologerAccount from '../astrologerAccount';
import axios from 'axios';
const url = process.env.REACT_APP_BASE_API;
export default (props) => {
   
    return (
        <Container
            fluid
            className={classNames('content', { 'is-open': props.isOpen })}
        >
            <NavBar toggle={props.toggle} />
            <Switch>
                <Route path="/astrologer/dashboard" component={Dashboard} />
                <Route exact path="/astrologer/profile" component={Profile} />
                <Route
                    exact
                    path="/astrologer/chat-history"
                    component={ChatHistory}
                />
                <Route
                    exact
                    path="/astrologer/call-history"
                    component={CallHistory}
                />
                <Route exact path="/astrologer/earning" component={Earning} />
                <Route exact path="/astrologer/chat" component={Chat} />
                <Route exact path="/astrologer/faq" component={() => 'FAQ'} />
                <Route exact path="/astrologer/menu-3" component={Menu3} />
                <Route exact path="/astrologer/account" component={AstrologerAccount} />
                <Route
                    exact
                    path="/astrologer/page-1"
                    component={() => 'Page-1'}
                />
                <Route
                    exact
                    path="/astrologer/page-2"
                    component={() => 'Page-2'}
                />

                <Redirect from="/astrologer" to="/astrologer/dashboard" />
            </Switch>
        </Container>
    );
};
