import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';

import { getVastu } from '../../../../store/actions/services';

const Muhurat = (props) => {
    const dispatch = useDispatch();
    const vastus = useSelector((state) => state.services.vastu);

    useEffect(() => {
        dispatch(getVastu());
    }, []);

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Price</th>
                        {/* <th>Price in </th> */}
                    </tr>
                </thead>
                <tbody>
                    {vastus &&
                        vastus.map((vastu, index) => (
                            <tr key={vastu.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{vastu.title}</td>
                                {/* <td>{vastu.description}</td> */}
                                <td style={{ width: '50px', height: '50px' }}>
                                    <img
                                        src={require(`../../../../assets/images/vastu/${vastu.image}`)}
                                        alt={vastu.title}
                                    />
                                </td>
                                <td>{vastu.price}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
};

export default Muhurat;
