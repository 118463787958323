import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CometChat } from '@cometchat-pro/chat';

import ChatInput from './ChatInput';
import InfoBar from './InforBar';
import MessageBox from './MessageBox';
import Pusher from '../pusher';
import Dialog from './Dialog';

import { getChatRoom, getChatRoomm } from './../../../store/actions/push';

import initializedChat from '../../../helpers/CometChat';

const url = process.env.REACT_APP_BASE_API;

const Chat = (props) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState(msg);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [listenerId, setListenerId] = useState(
        localStorage.getItem('chatId')
    );

    const { chatUser, chatRoom } = useSelector((state) => state.push);
    const Hello = useSelector((state) => state.push);

    const user = useSelector((state) => state.user.data);

    function sendMessage(event) {
        event.preventDefault();
        if (message) {
            setMessages([...messages, { type: 'replies', text: message }]);
            setMessage('');
            sendMessages(message);
        }
    }

    let userId;

    useEffect(() => {
        dispatch(getChatRoom());
    }, []);
    useEffect(() => {
        const channel = Pusher.subscribe('chat');
        channel.bind('astro', (data) => {
            if (data.astroId === user.id) {
                dispatch(getChatRoomm());
                setShowDialog(true);
                setDialogMessage("Chat has ended by the User. You will be redirected to the home page shortly.");
                setTimeout(() => {
                    setShowDialog(false);
                    setDialogMessage("");
                    props.history.push('/astrologer/dashboard');
                }, 3000);
            }
        });

        // return () => channel.unbind();
    }, []);

    useEffect(() => {
        if (user) {
            initializedChat
                .then(
                    CometChat.login(user.authToken).then(
                        (user) => {
                            console.log('Login Successful:');
                        },
                        (error) => {
                            console.log('Login failed with exception:', {
                                error,
                            });
                        }
                    )
                )
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [user]);

    const sendMessages = (messageText) => {
        saveMessage(messageText);
        const receiverType = CometChat.RECEIVER_TYPE.USER;
        const textMessage = new CometChat.TextMessage(
            chatUser.chatId,
            messageText,
            receiverType
        );

        CometChat.sendMessage(textMessage).then(
            (message) => {
                console.log('Message sent successfully:');
                // Do something with message
            },
            (error) => {
                console.log('Message sending failed with error:', error);
                // Handle any error
            }
        );
    };

    const receiveMessage = () => {
        CometChat.addMessageListener(
            listenerId,
            new CometChat.MessageListener({
                onTextMessageReceived: (message) => {
                    console.log('Message received successfully:');
                    // Handle text message
                    setMessages([
                        ...messages,
                        { type: 'sent', text: message.text },
                    ]);
                },
                onMessagesDelivered: (messageReceipt) => {
                    console.log('Message Delivered:');
                },
                onMessagesRead: (messageReceipt) => {
                    console.log('Message Read:');
                },
                onTypingStarted: (typingIndicator) => {
                    console.log('Typing started:');
                    const { currentlyTyping } = this.state;
                    const { sender } = typingIndicator;
                    if (currentlyTyping.length > 0) {
                        if (
                            !currentlyTyping.some(
                                (element) => element.uid === sender.uid
                            )
                        ) {
                            currentlyTyping.push(sender);
                        }
                    } else {
                        currentlyTyping.push(sender);
                    }
                    this.setState({
                        currentlyTyping,
                    });
                },
                onTypingEnded: (typingIndicator) => {
                    console.log('Typing ended:');
                    const { currentlyTyping } = this.state;
                    const { sender } = typingIndicator;
                    const newCurrentlyTyping = currentlyTyping.filter(
                        (element) => element.uid !== sender.uid
                    );
                    this.setState({
                        currentlyTyping: newCurrentlyTyping,
                    });
                },
            })
        );
    };
    useEffect(() => {
        receiveMessage();
        return () => CometChat.removeCallListener(listenerId);
    });

    const saveMessage = (msg) => {
        fetch(`${url}/chat/${chatRoom?.chatRoomId}/saveMessage`, {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                userId: chatUser.id,
                astroId: user.id,
                msg: msg,
                userType: 'astro',
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) console.log(result.message);
            });
    };
    return (
        <>
            <Dialog
                open={showDialog}
                toggle={false}
                className="my-model"
                dismiss
                message={dialogMessage}
            />
            <div className="dash-wapp">
                <h1 className="heading-tit">Chat</h1>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="chat-info">
                                If chat window does not show after accepting request
                                then please refresh the page.
                            </div>
                        </div>
                        {!chatUser && !chatRoom ? (
                            <div>There isn't any chat availabel now.</div>
                        ) : (
                            <div className="col-12 col-md-8">
                                <div id="frame">
                                    <div className="content" id="content">
                                        <InfoBar
                                            name={chatUser ? chatUser.name : 'User'}
                                        />

                                        <MessageBox messages={messages} />

                                        <ChatInput
                                            message={message}
                                            setMessage={setMessage}
                                            sendMessage={sendMessage}
                                            saveMessage={saveMessage}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

var msg = [];
// var msg = [
//     {
//         type: 'sent',
//         text:
//             'Lorem Ipsum is simply dummy textof the printing and typesetting industry.',
//     },
//     {
//         type: 'replies',
//         text:
//             'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
//     },
//     {
//         type: 'replies',
//         text:
//             'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 yearsold.',
//     },
//     {
//         type: 'sent',
//         text:
//             'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,',
//     },
//     {
//         type: 'replies',
//         text:
//             'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections',
//     },
//     {
//         type: 'replies',
//         text: 'What are your choices when someone puts a gun to your head?',
//     },
//     {
//         type: 'sent',
//         text:
//             'Lorem Ipsum which looks reasonable. The generated LoremIpsum is therefore always free from repetition, injected humour, or non-characteristic words etc.',
//     },
//     {
//         type: 'replies',
//         text: 'Many desktop publishing packages and web page editors',
//     },
// ];

export default Chat;
