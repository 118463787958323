import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useFormik } from "formik";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import styles from '../../../Styles/Admin.module.css'
import { useHistory } from 'react-router-dom';
import moment from 'moment'


const Index = () => {
  const today = new Date();
  const [rechargeList, setRechargeList] = useState()
  const { location } = useHistory()
  const [data, setData] = useState(location.state.rechargeDetailsList)

  const startDate = location.state.startDate
  const endDate = location.state.endDate
  const url = process.env.REACT_APP_BASE_API

  const ApiCall = async () => {
    try {
      const res = await axios(url + `/admin/astrologer-recharge-list?fromDate=${formik.values.startDate == '' ? startDate : formik.values.startDate}&toDate=${formik.values.endDate == '' ? endDate : formik.values.endDate}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + localStorage.getItem('token')
        },
        method: "get",
      });
      if (res.data.status == 'sucess') {
        setData(res.data.data.rechargeList)
        setRechargeList(res.data.data.rechargeList)
      }
    }
    catch (err) {
      console.log('Error', err)
    }
  }
  const formik = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
    },
    onSubmit: (values) => {
      ApiCall(values)
    },
  });

  useEffect(() => {
    ApiCall()
  }, [])

  const style =
  {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px'
  }
  return (
    <>
      <div >
        <Form
          onSubmit={formik.handleSubmit} >
          <Row>
            <Col md={3} >
              <Input
                type="date"
                placeholder="Enter your Start Date..."
                name="startDate"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                max={formik.values.startDate}
              />
            </Col>
            <Col md={3}>
              <Input
                type="date"
                placeholder="Enter your Start Date..."
                name="endDate"
                value={formik.values.endDate}
                onChange={formik.handleChange}
                max={endDate}
                min={startDate}
              />
            </Col>
            <Col md={2}>
              <FormGroup >
                <Button className="btn" type="submit">
                  Submit
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
        <div style={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '5px' }}>
          <h4 style={{ textAlign: 'center' }}>Total Recharge List</h4>
          <div style={style}>
            <div style={{ width: '50%' }}>
              {rechargeList && rechargeList.length}
            </div>
          </div>
          <div className={styles.callMissed}>
            <table
              class="table table-striped table-bordered" l
              style={{
                border: '1px solid gray',
                borderRadius: '3px',
                backgroundColor: 'white',
              }}
            >
              <thead className={styles.astroView1}>
                <tr>
                  <th>S.No.</th>
                  <th>User  Name</th>
                  <th>E-Mail - Mobile No.</th>
                  <th>Amount Paid</th>
                  <th>Amount Credit</th>
                  <th>Wallet</th>
                  <th>Transaction Time</th>
                </tr>
              </thead>
              <tbody>

                {data && data.count > 0 ? data.rows.map((data, index) => {
                  return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.user.name}</td>
                    <td>{data.user.email + "/" + data.user.mobile}</td>
                    <td>{data.amountPaid}</td>
                    <td>{data.amountCredit}</td>
                    <td>{data.user.wallet}</td>
                    <td>{moment(data.createdAt).format('llll')}</td>
                  </tr>
                }):(
                  <p>*There isn't present any data yet.</p>
              )}
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </>
  )
}
export default Index
