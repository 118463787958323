import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

const url = process.env.REACT_APP_BASE_API
const Index = () => {
    const [display, setDisplay] = useState(false);
    const [msg, setMsg] = useState("");
    const [err, setErr] = useState("");
    const Email = (value) => {
        axios.post(url + "/admin/verify-token", value, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setErr(res.data.status)
            if (res.data.status == 'success') {
                setDisplay(true);
                setMsg(res.data.msg)
                setTimeout(() => {
                    setDisplay(false)
                    setMsg('')
                }, 3000);
            } else if (res.data.status == 'failed') {
                setDisplay(true);
                setMsg(res.data.msg)
                setTimeout(() => {
                    setDisplay(false)
                    setMsg('')
                }, 3000);
            }
        }).catch((err) => {
            console.log('Error ::::', err)
        })
    };

    const inputError = (formik, input) => {
        return (
            formik.errors[input] &&
            formik.touched[input] && (
                <div
                    style={{
                        color: "rgb(235, 54,54)",
                        fontSize: "12px",
                        marginLeft: '5px',
                        fontWeight: 'bold'
                    }}
                >
                    {formik.errors[input]}
                </div>
            )
        );
    };

    const formik = useFormik({
        initialValues: {
            email: "",

        },
        onSubmit: (values) => {
            Email(values)
        },

        validationSchema: () =>
            Yup.object().shape({
                email: Yup.string()
                    .required("Required!"),
            })
    })

    return (
        <>
            <div style={{ width: '100vw', height: '200vh' }}>
                <h2 style={{ textAlign: 'center', borderBottom: '2px solid gray', width: '18vw', marginLeft: '33vw', paddingTop: '10px' }}>Verification Mail </h2>
                <div style={{ width: '30vw', backgroundColor: '#ffff', marginLeft: '28vw', marginTop: '3%', borderRadius: '10px', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',paddingBottom: '15px' }}>

                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        {display && <div style={{ backgroundColor: `${err == 'failed' ? '#ff4646' : '#008e00ab'}`, borderRadius: '10px', color: 'white', margin: '10px 0px', padding: '10px', width: 'fit-content', textAlign: 'center' }}>{msg}</div>}
                        <label style={{ marginLeft: '2vw', marginTop: '15px', fontWeight: 600 }}>Email :</label>
                        <input
                            type="text"
                            placeholder="Enter Your Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            style={{
                                width: '90%',
                                padding: '9px',
                                margin: '15px',
                                marginTop: '3vh',
                                display: 'inlineblock',
                                border: '1px solid #ccc',
                                boxSizing: "border-box",
                                borderRadius: '50px'
                            }} />
                        {inputError(formik, "verifyEmail")}
                        <div style={{
                                    width: '100%', textAlign: 'center'
                        }}>
                            <button onClick={formik.handleSubmit} type="submit"
                                style={{
                                    width: '30%',
                                    padding: '10px',
                                    margin: 'auto',
                                    marginTop: '1vh',
                                    display: 'inlineblock',
                                    border: '1px solid #ccc',
                                    boxSizing: "border-box",
                                    borderRadius: '50px',
                                    backgroundColor: 'orange',
                                    color: 'white',
                                    fontWeight: 600
                                }}  >Submit</button>
                        </div>
                    </form>

                </div>



            </div>

        </>
    );
};

export default Index;