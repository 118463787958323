import axios from 'axios'
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const url = process.env.REACT_APP_BASE_API;

const styles = {
    color:{
        color: 'black'
    }
}
const AstrologerAccount = () => {
    const { state } = useLocation();
    const [user, setUser] = useState('');
   
    const getMe = async () => {
        const res = await axios.get(url + '/account/me', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setUser(res.data.user);
        }
    };
    
    useEffect(() => {
        getMe();
    }, []);
    return (
        <div style={{padding: '5px'}}>
            <h3 style={{ marginBottom: '20px' }}>Astrologer Profile</h3>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                {/* <div > */}
                <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '15px' }}>
                    <h5>Basic Details</h5>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Name:</strong>{' '}<span>{user.name}</span></p>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Email:</strong>{' '}<span>{user.email}</span></p>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Mobile:</strong>{' '}<span>{user.mobile}</span></p>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Gender:</strong>{' '}<span>{user.gender}</span></p>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Wallet:</strong>{' '}<span>{user.wallet}</span></p>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Address: </strong>{' '}<span>{state.locality}{' '}
                        {state.city}</span>{' '}<span>{state.state}</span>{' '}<span>{state.pinCode}</span>{' '}<span>{state.country}</span></p>
                    <p style={{padding: '5px'}}><strong style={styles.color}>About:</strong>{' '}<span>{state.about}</span></p>
                </div>
                <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '15px' }}>
                    <h5>Status</h5>
                    <p style={{padding: '5px'}}><span>{state.status}</span></p>
                    <h5>Services</h5>
                    <p style={{padding: '5px'}}><span>{state.services+ ' '}</span></p>
                    <h5>Languages</h5>
                    <p style={{padding: '5px'}}><span>{state.languages+ ' '}</span></p>
                    <h5>Experience</h5>
                    <p style={{padding: '5px'}}><span>{state.experience + " "}Years</span></p>
                    <h5>Price</h5>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Chat Price: </strong>{' '}<span>{state.chatPrice}</span></p>
                    <p style={{padding: '5px'}}><strong style={styles.color}>Call Price: </strong>{' '}<span>{state.audioCallPrice}</span></p>
                </div>
            </div>
            <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '15px' }}>
                <h5>Account Details</h5>
                <p style={{padding: '5px'}}><strong style={styles.color}>Bank Name:</strong>{' '}<span>{state.bankName}</span></p>
                <p style={{padding: '5px'}}><strong style={styles.color}>Account Number:</strong>{' '}<span>{state.accNumber}</span></p>
                <p style={{padding: '5px'}}><strong style={styles.color}>IFSE Code:</strong>{' '}<span>{state.ifsc}</span></p>
            </div>
        </div>
    )
}

export default AstrologerAccount