import axios from 'axios';
import history from '../../helpers/history';
import {
    PROFILE_UPDATE_START,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_UPDATE_FAILED,
    BASIC_PROFILE_UPDATE_START,
    BASIC_PROFILE_UPDATE_SUCCESS,
    // FETCH_EARNING_START,
} from './types';

const URL = process.env.REACT_APP_BASE_API;

// updateStatus1

export { updateStatus, updateAstroProfile, updateBasicDetails };

function updateStatus(status) {
    return async (dispatch) => {
        dispatch({ type: 'UPDATE_STATUS_START' });
        try {
            const { data } = await axios({
                url: `${URL}/astro/updateStatus`,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                data: { status: status },
            });
            
            if (data.status === 'success') {
                dispatch({
                    type: 'UPDATE_STATUS_SUCCESS',
                    status: data.data.astroStatus,
                });
                window.location.href = '/';
            }
            if (data.status === 'fail') {
                dispatch({ type: 'UPDATE_STATUS_FAIL', payload: data.data });
            }
        } catch (err) {
            console.log('Something went wrong');
        }
    };
}
// function updateStatus1(status1) {
//     console.log(status1);
//     return async (dispatch) => {
//         dispatch({ type: 'UPDATE_STATUS_STARTT' });
//         try {
//             const { data } = await axios({
//                 url: `${URL}/astro/updateStatus`,
//                 method: 'post',
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`,
//                 },
//                 data: { status: status1 },
//             });

//             if (data.status === 'success') {
//                 dispatch({
//                     type: 'UPDATE_STATUS_SUCCESST',
//                     status: data.data.status1,
//                 });
//             }
//             if (data.status === 'fail') {
//                 dispatch({ type: 'UPDATE_STATUS_FAILT', payload: data.data });
//             }
//         } catch (err) {
//             console.log('Something went wrong');
//         }
//     };
// }

function updateBasicDetails(formValues) {
    return async (dispatch) => {
        dispatch({ type: BASIC_PROFILE_UPDATE_START });
        try {
            const formdata = new FormData();
            for (let key in formValues) {
                formdata.append(key, formValues[key]);
            }
            const { data } = await axios({
                url: `${URL}/user/updateMe`,
                method: 'patch',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                data: formdata,
            });
            if (data.status === 'success') {
                dispatch({ type: BASIC_PROFILE_UPDATE_SUCCESS });
            }
        } catch (err) {
            console.log('Something went wrong');
        }
    };
}

function updateAstroProfile(formValues) {
    return async (dispatch) => {
        dispatch({ type: PROFILE_UPDATE_START });
        try {
            const { data } = await axios({
                url: `${URL}/astro/updateMe`,
                method: 'patch',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                data: formValues,
            });
            if (data.status === 'success') {
                dispatch({ type: PROFILE_UPDATE_SUCCESS });
            }
        } catch (err) {
            console.log(err);
        }
    };
}

// function getEarning(startDate, endDate) {
//     return async (dispatch) => {
//         try {
//             const response = await axios({
//                 url: `${URL}/total-earning/earning`,
//                 method: 'get',

//                 headers: {
//                     Authorization: 'Bearer ' + localStorage.getItem('token'),
//                     'Content-Type': 'application/json',
//                 },
//             });

//             if (response.data.status === 'success') {
//                 const { data } = response;
//                 const earningAstro = data.total ? data.total : null;

//                 dispatch({
//                     type: FETCH_EARNING_START,
//                     payload: { startDate, endDate },
//                     message: 'Fetch Earning Successfully',
//                 });
//             }
//         } catch (err) {
//             console.log(err);
//         }
//     };
// }
