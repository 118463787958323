import axios from 'axios';
import history from '../../helpers/history';
import {
    TOKEN_SEND_SUCCESS,
    TOKEN_SEND_FAIL,
    MESSAGE_RECEIVED,
    NOTIFICATION_SEND_START,
    NOTIFICATION_SEND_SUCCESS,
    NOTIFICATION_SEND_FAIL,
    CHATROOM_FETCH_SUCCESS,
} from './types';

const url = process.env.REACT_APP_BASE_API;

export const sendTokenToServer = (token) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: 'post',
                url: `notification/token`,
                baseURL: url,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                data: { token },
            });
            if (response.data.status === 'success') {
                localStorage.setItem('fcmToken', token);
                dispatch({
                    type: TOKEN_SEND_SUCCESS,
                    data: token,
                    message: 'Token send successfully',
                });
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: TOKEN_SEND_FAIL,
                    errorMessage: err.response.data.message,
                });
            } else if (err.request) {
                console.log(err.request);
                dispatch({
                    type: NOTIFICATION_SEND_FAIL,
                    errorMessage: 'Something went wrong',
                });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(err.message);
            }
            console.log(err.config);
        }
    };
};

export const handleMessage = (payload) => (dispatch) => {
    dispatch({ type: MESSAGE_RECEIVED, user: JSON.parse(payload?.user) });
};

export const getChatRoom = () => async (dispatch) => {
    try {
        const response = await axios({
            method: 'get',
            url: 'chat/chatroom',
            baseURL: url,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });

        if (response.data.status === 'success') {
            const { data } = response;
            const chatUser = data.chatRoom ? data.chatUser : false;

            dispatch({
                type: CHATROOM_FETCH_SUCCESS,
                chatUser,
                chatRoom: data.chatRoom,
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const getChatRoomm = () => async (dispatch) => {
    try {
        dispatch({
            type: CHATROOM_FETCH_SUCCESS,
            chatUser : false,
            chatRoom: false,
        });
    }
    catch (err) {
        console.log(err);
    }
};

export const getCallRoom = () => async (dispatch) => {
    try {
        const response = await axios({
            method: 'get',
            url: 'chat/chatroom',
            baseURL: url,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });

        if (response.data.status === 'success') {
            const { data } = response;
            const chatUser = data.chatRoom ? data.chatUser : false;

            dispatch({
                type: CHATROOM_FETCH_SUCCESS,
                chatUser,
                chatRoom: data.chatRoom,
            });
        }
    } catch (err) {
        console.log(err);
    }
};

// export const getEarning = (startDate, endDate) => async (dispatch) => {
//     try {
//         const { response } = await axios({
//             method: 'get',
//             url: '/total-earning/earning',
//             baseURL: url,
//             headers: {
//                 Authorization: 'Bearer ' + localStorage.getItem('token'),
//                 'Content-Type': 'application/json',
//             },
//             payload: { startDate, endDate },
//         });

//         if (response.data.status) {
//             const { data } = response;
//             const earning = data.total ? data.earning : null;

//             dispatch({
//                 type: FETCH_EARNING_START,
//                 earning,
//                 total: data.total,
//                 message: 'Earning fetch success',
//             });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

export const notifyUser = (payload) => async (dispatch) => {
    dispatch({ type: NOTIFICATION_SEND_START });
    try {
        const response = await axios({
            method: 'post',
            url: `notification/notify`,
            baseURL: url,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            data: payload,
        });
        if (response.data.status === 'success') {
            console.log("Notification send successfully", response.data);
            dispatch({
                type: NOTIFICATION_SEND_SUCCESS,
                message: 'Message send successfully',
            });
        }
    } catch (err) {
        console.log(err);
        if (err.response) {
            console.log(err.response);
            dispatch({
                type: TOKEN_SEND_FAIL,
                errorMessage: err.response.data.message,
            });
        } else if (err.request) {
            console.log(err.request);
            dispatch({
                type: TOKEN_SEND_FAIL,
                errorMessage: 'Something went wrong',
            });
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log(err.message);
        }
        console.log(err.config);
    }
};
