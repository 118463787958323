import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import NavBar from '../Navbar/Navbar';
import Contact from '../../Admin/Contact';
import Dashboard from '../Dashboard';
import Users from '../Users';
import Muhurat from '../Pages/Services/Muhurat';
import Vastu from '../Pages/Services/Vastu';
import HandmadeReport from '../Pages/Services/HandmadeReport';
import AstrologerPage from '../Astrologer';
import CreateAstro from '../Astrologer/create'
import AstrologerActivity from '../Astrologer/AstrologerActivity'
import Astrologers from '../Astrologer/AstroView';
import AstroProfile from "../Astrologer/AstroProfile";
import AllChatDetails from "../Astrologer/AllChatDetails"
import AllChatMissedDetails from "../Astrologer/AllChatMissedDetails"
import AllCallDetails from "../Astrologer/AllCallDetails"
import AllCallMissedDetails from "../Astrologer/AllCallMissedDetails"
import AstroOnlineOffline from "../Astrologer/AstroOnlineOffline"
import TotaRechargeList from "../Astrologer/Stats/TotalRechargeList"
import NewUserAccount from "../Astrologer/Stats/NewUserAccount"
import VerifiedAccount from "../Astrologer/Stats/VerifiedAccount"
import UnverifiedAccount from "../Astrologer/Stats/UnverifieduserAccount"
import AstrologerChatMissed from "../Astrologer/Stats/AstrologerChatMissed"
import AstrologerCallMissed from "../Astrologer/Stats/AstrologerCallMissed"
import UserCallMissed from "../Astrologer/Stats/UserCallMissed"
import ConnectedCall from "../Astrologer/Stats/ConnectedCall"
import UserChatMissed from "../Astrologer/Stats/UserChatMissed"
import ChatConnected from "../Astrologer/Stats/ChatConnected"
import JyotirvidSeo from "../JyotirvidSeo"
import AstrologerVideoCallMissed from "../Astrologer/Stats/AstrologerVideoCallMissed"
import UserVideoCallMissed from "../Astrologer/Stats/UserVideoCallMissed"
import VideoCallConnected from "../Astrologer/Stats/VideoCallConnected"
import AstroVideoCallDetails from "../Astrologer/AstroVideoCalDetails"
import AstroVideoCallMissed from "../Astrologer/AstroVideoCallMissed"
import VerificationMail from "../VerificationMail"
import FreeKundaliUsers from '../FreeKundaliUsers'
import freeMatchMakingUsers from '../FreeMatchMakingUsers';

export default (props) => {
    return (
        <Container fluid className={classNames('content', { 'is-open': props.isOpen })}>
            <NavBar toggle={props.toggle} />
            <Switch>
                <Route exact path="/admin/dashboard" component={Dashboard} />
                <Route exact path="/admin/users" component={Users} />
                <Route exact path="/admin/astro-activity" component={AstrologerActivity} />
                <Route exact path="/admin/astro" component={Astrologers} />
                <Route exact path="/admin/astro-profile" component={AstroProfile} />
                <Route exact path="/admin/astro-chat-details" component={AllChatDetails} />
                <Route exact path="/admin/astro-chat-missed-details" component={AllChatMissedDetails} />
                <Route exact path="/admin/astro-call-details" component={AllCallDetails} />
                <Route exact path="/admin/astro-call-missed-details" component={AllCallMissedDetails} />
                <Route exact path="/admin/astro-astro-online-offline" component={AstroOnlineOffline} />
                <Route exact path="/admin/stats-total-recharge-list" component={TotaRechargeList} />
                <Route exact path="/admin/stats-new-user-list-account" component={NewUserAccount} />
                <Route exact path="/admin/stats-verified-account" component={VerifiedAccount} />
                <Route exact path="/admin/stats-unverified-user-account" component={UnverifiedAccount} />
                <Route exact path="/admin/stats-astrologer-chat-missed" component={AstrologerChatMissed} />
                <Route exact path="/admin/stats-astrologer-call-missed" component={AstrologerCallMissed} />
                <Route exact path="/admin/stats-user-call-missed" component={UserCallMissed} />
                <Route exact path="/admin/stats-connected-calls" component={ConnectedCall} />
                <Route exact path="/admin/stats-user-chat-missed" component={UserChatMissed} />
                <Route exact path="/admin/stats-chat-connected" component={ChatConnected} />
                <Route exact path="/admin/add-astrologer-seo" component={JyotirvidSeo} />
                <Route exact path="/admin/stats-astrologer-video-call-missed" component={AstrologerVideoCallMissed} />
                <Route exact path="/admin/stats-video-call-connected" component={VideoCallConnected} />
                <Route exact path="/admin/stats-user-video-call-missed" component={UserVideoCallMissed} />
                <Route exact path="/admin/astro-video-call-details" component={AstroVideoCallDetails} />
                <Route exact path="/admin/astro-video-call-missed" component={AstroVideoCallMissed} />
                <Route exact path="/admin/verification-mail" component={VerificationMail} />
                <Route exact path="/admin/faq" component={() => 'FAQ'} />
                <Route exact path="/admin/contact" component={Contact} />
                <Route exact path="/admin/Home-1" component={() => 'Home-1'} />
                <Route exact path="/admin/Home-2" component={() => 'Home-2'} />
                <Route exact path="/admin/Home-3" component={() => 'Home-3'} />
                <Route exact path="/admin/muhurat" component={Muhurat} />
                <Route exact path="/admin/vastu" component={Vastu} />
                <Route exact path="/admin/add-astrologer-seo" component={JyotirvidSeo} />
                <Route exact path="/admin/free-kundali-users" component={FreeKundaliUsers} />
                <Route exact path='/admin/free-matchmaking-users' component={freeMatchMakingUsers} />
                <Route
                    exact
                    path="/admin/handmade-report"
                    component={HandmadeReport}
                />
                <Route
                    exact
                    path="/admin/astrologerPage"
                    component={AstrologerPage}
                />
                <Route
                    exact
                    path="/admin/createAstro"
                    component={CreateAstro}
                />
                <Route exact path="/admin/page-2" component={() => 'page-2'} />
                <Route exact path="/admin/page-3" component={() => 'page-3'} />
                <Route exact path="/admin/page-4" component={() => 'page-4'} />
                <Redirect from="/admin" to="/admin/dashboard" />
                <Redirect from="/admin/dashboard" to="/admin/login" />
            </Switch>
        </Container>
    );
};
