import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import {
    updateAstroProfile,
    updateBasicDetails,
} from '../../store/actions/astroProfile';
import BasicDetailsForm from './BasicDetailsForm';
import AstroProfileForm from './AstroProfileForm';
import UpdatePasswordForm from './UpdatePasswordForm';

const Menu = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const user = useSelector((state) => state.user.data);
    const dispatch = useDispatch();

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const handleAstroProfile = (formValues) => {
        dispatch(updateAstroProfile(formValues));
    };
    const handleBasicDetails = (formValues) => {
        dispatch(updateBasicDetails(formValues));
    };

    return (
        <div className="dash-wapp">
            <h1 className="heading-tit">Your Profile </h1>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile-edit-war">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: activeTab === '1',
                                        })}
                                        onClick={() => {
                                            toggle('1');
                                        }}
                                    >
                                        Basic Details
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: activeTab === '2',
                                        })}
                                        onClick={() => {
                                            toggle('2');
                                        }}
                                    >
                                        Astro profile
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: activeTab === '3',
                                        })}
                                        onClick={() => {
                                            toggle('3');
                                        }}
                                    >
                                        Update Password
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: activeTab === '4',
                                        })}
                                        onClick={() => {
                                            toggle('4');
                                        }}
                                    >
                                        Astrological Details
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className="padding" tabId="1">
                                    <BasicDetailsForm
                                        {...user}
                                        handleFormSubmit={handleBasicDetails}
                                    />
                                </TabPane>
                                <TabPane tabId="2">
                                    <AstroProfileForm
                                        handleFormSubmit={handleAstroProfile}
                                    />
                                </TabPane>
                                <TabPane tabId="3">
                                    <UpdatePasswordForm />
                                </TabPane>
                                {/* <TabPane tabId="4">
                                    <AstroDetails />
                                </TabPane> */}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;
