import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { NavItem, NavLink, Nav } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCopy, faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import SubMenu from './SubMenu';

const SideBar = (props) => {
    const history = useHistory();
    const [color1, setColor1] = useState('')
    const [color2, setColor2] = useState('')
    const [color3, setColor3] = useState('')
    const [color4, setColor4] = useState('')
    const [color5, setColor5] = useState('')
    const [color6, setColor6] = useState('')
    const [color7, setColor7] = useState('')

    useEffect(() => {
        history.location.pathname == "/admin/dashboard" ? setColor1('white') : setColor1('')
        history.location.pathname == "/admin/astro" ? setColor2('white') : setColor2('')
        history.location.pathname == "/admin/users" ? setColor3('white') : setColor3('')
        history.location.pathname == "/admin/verification-mail" ? setColor4('white') : setColor4('')
        history.location.pathname == "/admin/add-astrologer-seo" ? setColor5('white') : setColor5('')
        history.location.pathname == '/admin/free-kundali-users' ? setColor6('white') : setColor6('')
        history.location.pathname == '/admin/free-matchmaking-users' ? setColor7('white') : setColor7('')
    }, [history.location.pathname])

    return <div className={classNames('sidebar', { 'is-open': props.isOpen })}>
        <div className="sidebar-header">
            <span color="info" onClick={props.toggle} style={{ color: '#fff' }}>
                &times;
            </span>
            <h3 >Jyotirvid</h3>
        </div>
        <div className="side-menu">
            <Nav vertical className="list-unstyled pb-3">
                <p>Menu</p>
                <NavItem style={{ color: `${color1}` }}>
                    <NavLink tag={Link} to={'/admin/dashboard'}>
                        <FontAwesomeIcon icon={faHome} className="mr-2" />
                        Dashboard
                    </NavLink>
                </NavItem>
                <NavItem style={{ color: `${color2}` }} >
                    <NavLink tag={Link} to={'/admin/astro'}>
                        <FontAwesomeIcon icon={faUsers} className="mr-2" />
                        Astrologers
                    </NavLink>
                </NavItem>
                <NavItem style={{ color: `${color3}` }}>
                    <NavLink tag={Link} to={'/admin/users'}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Users
                    </NavLink>
                </NavItem>
                <NavItem style={{ color: `${color4}` }}>
                    <NavLink tag={Link} to={'/admin/verification-mail'}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Varification Mail
                    </NavLink>
                </NavItem>
                <NavItem style={{ color: `${color5}` }}>
                    <NavLink tag={Link} to={'/admin/add-astrologer-seo'}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Add Astrologer SEO
                    </NavLink>
                </NavItem>
                <NavItem style={{ color: `${color6}` }}>
                    <NavLink tag={Link} to={'/admin/free-kundali-users'}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Free Kundali Users
                    </NavLink>
                </NavItem>
                <NavItem style={{ color: `${color7}` }}>
                    <NavLink tag={Link} to={'/admin/free-matchmaking-users'}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Free Match Making Users
                    </NavLink>
                </NavItem>
                <SubMenu title="Services" icon={faCopy} items={submenus[0]} />
            </Nav>
        </div>
    </div>
};

const submenus = [
    [
        {
            target: 'muhurat',
            title: 'Muhurat',
        },
        {
            title: 'Vastu',
            target: 'vastu',
        },
        {
            title: 'Handmade Report',
            target: 'handmade-report',
        },
    ],
];

export default SideBar;
