import React, { useState, useEffect, Fragment } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styles from '../../Styles/Admin.module.css'
import moment from 'moment'


const url = process.env.REACT_APP_BASE_API

const Index = () => {
    const [chatDetails, setChatDetails] = useState();
    const { location } = useHistory()
    const chatDetail = location.state.chatDetails;
    const astro = location.state.astro
    const style = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px'
    }
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <div style={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '5px' }}>
                    <h4 style={{ textAlign: 'center' }}> {astro && astro} : Chat Details Connected</h4>
                    <div style={style}>
                        <div style={{ width: '50%' }}>
                            {chatDetails && chatDetails.count}
                        </div>
                    </div>
                    <div className={styles.astroView}>
                        <table
                            class="table table-striped table-bordered" l
                            style={{
                                border: '1px solid gray',
                                borderRadius: '3px',
                                backgroundColor: 'white',
                            }}
                        >
                            <thead className={styles.astroView1}>
                                <tr>
                                    <th>S.No.</th>
                                    <th>User Name & Mobile No</th>
                                    <th>Amount Charged</th>
                                    <th>Chat Duration</th>
                                    <th>Chat Rate</th>
                                    <th>Opening Balance - Closing Balance</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chatDetail.rows && chatDetail.rows.length > 0 && chatDetail.rows.map((data, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.user.name + "/" + data.user.mobile}</td>
                                        <td>{data.amountCharged}</td>
                                        <td>{data.chatDuration}</td>
                                        <td>{data.chatRate}</td>
                                        <td>{data.openingBalance + "/" + data.closingBalance}</td>
                                        <td>{moment(data.startTime).format('DD-MM-YYYY H:mm:ss ')}</td>
                                        <td>{moment(data.endTime).format('DD-MM-YYYY H:mm:ss ')}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;
