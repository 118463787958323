import firebase from 'firebase';

import store from './store';
import { sendTokenToServer, handleMessage } from './store/actions/push';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: 'AIzaSyCyqe9NcX5TU-hRTfDpoXDtt4A7pASC_HM',
        authDomain: 'jyotirvid-1063f.firebaseapp.com',
        databaseURL: 'https://jyotirvid-1063f.firebaseio.com',
        projectId: 'jyotirvid-1063f',
        storageBucket: 'jyotirvid-1063f.appspot.com',
        messagingSenderId: '112087954338',
        appId: '1:112087954338:web:4c5867a5c92fe804c838fb',
        measurementId: 'G-CZ4T0XHYZL',
    });
}
const messaging = firebase.messaging();
export const askForPermission = async () => {
    try {
        await messaging.requestPermission();
        const token = await messaging.getToken();
        store.dispatch(sendTokenToServer(token));

        return token;
    } catch (error) {
    }
};

messaging.onMessage((payload) => {
    store.dispatch(handleMessage(payload.data));
});

messaging.onTokenRefresh(() => {
    messaging
        .getToken()
        .then((refreshedToken) => {
            // setTokenSentToServer(false);
            store.dispatch(sendTokenToServer(refreshedToken));
        })
        .catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
            // showToken('Unable to retrieve refreshed token ', err);
        });
});

navigator.serviceWorker.addEventListener('message', (event) => {
    store.dispatch(handleMessage(event.data.firebaseMessaging?.payload?.data));
    console.log(event.data.firebaseMessaging?.payload?.data);
});
