import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';

import PrivateRoute from './PrivateRoute';
import PrivateRouteAdmin from './PrivateRouteAdmin';
import AdminLogin from './components/Account/AdminLogin';
import AstrologerLogin from './components/Account/AstrologerLogin';
import AstrologerSignup from './components/Account/AstrologerSignup';
import AdminDashboard from './components/Admin';
import AstroDashboard from './components/Astrologers';
import ForgotPassword from './components/Account/ForgotPassword';
import ResetPassword from './components/Account/ResetPassword';
import Earning from './components/Astrologers/Earning';
import superAdminLogin from './components/Admin/superAdminLogin'
import superAdminDashboard from './components/Admin/superAdminDashboard'
// import AdminSignup from './components/Account/AdminSignup';
// import Chat from './componentss/Chat/Chat';

function App() {
    useEffect(() => {});
    return (
        <div style={{height: '100vh', overflow: 'hidden'}}>
            <Switch>
                <Route exact path="/superAdmin/login" component={superAdminLogin} />
                <Route exact path="/admin/login" component={AdminLogin} />
                <Route exact path="/superAdminDashboard" component={superAdminDashboard} />
                {/* <Route path="/admin" component={AdminDashboard} /> */}
                <Redirect from="/admin/login" to="/admin/dashboard" />

                <Route exact path="/login" component={AstrologerLogin} />
                <Route exact path="/signup" component={AstrologerSignup} />
                <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                />
                <Route
                    exact
                    path="/reset-password/:resetToken"
                    component={ResetPassword}
                />

                <PrivateRoute
                    exact
                    path="/astrologer/:dashboard"
                    component={AstroDashboard}
                />
                <PrivateRouteAdmin
                    exact
                    path="/admin/:dashboard"
                    component={AdminDashboard}
                />
                {/* <Redirect exact from="/astrloger" to="/astrloger/dashboard" /> */}
                {/* <Route exact path="/astrologer/chat" component={Chat} /> */}
                <Redirect from="/" to="/astrologer/dashboard" />
                <Route exact path="/astrologer/earning" component={Earning} />
            </Switch>
        </div>
    );
}

export default App;
