import axios from 'axios';
import history from '../../helpers/history';
import {
    GET_USER_START,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    ASTRO_LOGIN_START,
    ASTRO_LOGIN_SUCCESS,
    ASTRO_LOGIN_FAIL,
    ASTRO_SIGNUP_START,
    ASTRO_SIGNUP_SUCCESS,
    ASTRO_SIGNUP_FAIL,
    ASTRO_LOGOUT,
} from './types';

const url = process.env.REACT_APP_BASE_API;

export { astroLogin, astroSignUp, getUser };

function getUser() {
    return async (dispatch) => {
        dispatch({ type: GET_USER_START });
        try {
            const { data } = await axios.get(`${url}/account/me`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (data.status === 'success') {
                localStorage.setItem('chatId', data.user.chatId);
                dispatch({ type: GET_USER_SUCCESS, user: data.user });
            }
        } catch (error) {
            console.log(error);
        }
    };
}
function astroLogin(formValues, referer) {
    return async (dispatch) => {
        dispatch({ type: ASTRO_LOGIN_START });
        try {
            formValues.role = 'astro'
            const response = await axios.post(
                `${url}/account/login`,
                formValues
            );
            if (response.data.success) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('chatId', response.data.user.chatId);
                dispatch({
                    type: ASTRO_LOGIN_SUCCESS,
                    data: response.data.user,
                    message: 'Login Successfully.',
                });
                history.push(referer ? referer : '/dashboard');
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: ASTRO_LOGIN_FAIL,
                    errorMessage: err.response.data.message,
                });
            } else if (err.request) {
                console.log(err.request);
                dispatch({
                    type: ASTRO_LOGIN_FAIL,
                    errorMessage: 'Something went wrong!',
                });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(err.message);
            }
            console.log(err.config);
        }
    };
}

function astroSignUp(formValues) {
    return async (dispatch) => {
        dispatch({ type: ASTRO_SIGNUP_START });
        try {
            const response = await axios.post(
                `${url}/account/signupAstro`,
                formValues
            );
            if (response.data.success) {
                localStorage.setItem('token', response.data.token);
                dispatch({
                    type: ASTRO_SIGNUP_SUCCESS,
                    data: response.data.user,
                });
                history.push('/astrologer/dashboard');
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: ASTRO_SIGNUP_FAIL,
                    errorMessage: err.response.data.message,
                });
            } else if (err.request) {
                console.log(err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(err.message);
                dispatch({
                    type: ASTRO_SIGNUP_FAIL,
                    errorMessage: 'Something went wrong!',
                });
            }
            console.log(err.config);
        }
    };
}

export const astroLogout = () => (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('admin');
    localStorage.removeItem('user');
    history.push('/login');
    dispatch({ type: ASTRO_LOGOUT, message: 'Logout Successfully' });
};

export const signOut = () => (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('admin');
    localStorage.removeItem('user');
    localStorage.removeItem('chatId');
    dispatch({ type: ASTRO_LOGOUT, message: 'Logout Successfully' });
};
