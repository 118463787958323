import {
    TOKEN_SEND_SUCCESS,
    TOKEN_SEND_FAIL,
    MESSAGE_RECEIVED,
    CHATROOM_FETCH_SUCCESS,
} from '../actions/types';

const INITIALSTATE = {};

export default (state = INITIALSTATE, action) => {
    switch (action.type) {
        case TOKEN_SEND_SUCCESS: {
            return {
                ...state,
            };
        }
        case TOKEN_SEND_FAIL:
            return {
                ...state,
            };
        case MESSAGE_RECEIVED:
            return {
                ...state,
                user: action.user,
            };

        case CHATROOM_FETCH_SUCCESS:
            return {
                ...state,
                chatUser: action.chatUser,
                chatRoom: action.chatRoom,
            };
        default:
            return state;
    }
};

// export const earningReducer = (
//     state = initialState,
//     { type, payload },
//     action
// ) => {
//     switch (type) {
//         case FETCH_EARNING_START:
//             return { ...state, total: action.total };
//         default:
//             return state;
//     }
// };
