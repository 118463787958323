import React, { useState } from 'react';
import { Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert } from 'reactstrap';

const url = process.env.REACT_APP_BASE_API;

function InputField({ name, formik, type, placeholder, label, ...props }) {
    return (
        <div className="col-lg-6">
            <div className="form-group">
                <div className="row">
                    {label ? (
                        <label className="col-lg-4 col-form-label">
                            {label}
                        </label>
                    ) : (
                        ''
                    )}
                    <div className="col-lg-8">
                        <input
                            type={type}
                            name={name}
                            placeholder={placeholder ? placeholder : label}
                            className="form-control"
                            {...props}
                            value={formik.values[name]}
                            onChange={formik.handleChange}
                        />
                        {formik.errors[name] && formik.touched[name] && (
                            <div
                                style={{
                                    color: 'rgb(235, 54,54)',
                                    fontSize: '14px',
                                }}
                            >
                                {formik.errors[name]}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Form() {
    const [visible, setVisible] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const onDismiss = () => setVisible(false);

    const addAstrologer = (values) => {
        fetch(`${url}/astro/createAstro`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsSuccess(true);
                    setMessage('Successfully Add Astrologer');
                    formik.resetForm();
                },
                (error) => {
                    console.log(error);
                    setIsError(true);
                    setError('Something went wrong. Try again');
                }
            );
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            locality: '',
            city: '',
            state: '',
            country: 'India',
            pinCode: '',
            chatPrice: '',
            audioCallPrice: '',
            reportPrice: '',
            experience: '',
            bankName: '',
            ifsc: '',
            accNumber: '',
            // verified: '',
        },
        onSubmit: (values) => {
            addAstrologer(values);
        },
        validationSchema: () =>
            Yup.object().shape({
                email: Yup.string().email('Invalid email').required('Required'),
                // verified: Yup.boolean().required('Required'),
                locality: Yup.string()
                    .required('Required')
                    .min(3, 'Please enter you locality.'),
                city: Yup.string().required('Required'),
                state: Yup.string().required('Required'),
                country: Yup.string().required('Required'),
                pinCode: Yup.number().required('Required'),
                chatPrice: Yup.number().required('Required'),
                reportPrice: Yup.number().required('Required'),
                audioCallPrice: Yup.number().required('Required'),
                experience: Yup.number().required('Required'),
                bankName: Yup.string().required('Required'),
                ifsc: Yup.string().required('Required'),
                accNumber: Yup.number().required('Required'),
            }),
    });

    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{ width: '90%', margin: 'auto' }}
        >
            <div className="row">
                <div className="col-lg-6">
                    {isSuccess && (
                        <Alert color="info" isOpen={visible} toggle={onDismiss}>
                            {message}
                        </Alert>
                    )}
                </div>
            </div>
            <div className="row row-bg-2" style={{ padding: '7px' }}>
                <div
                    className="col-lg-12 p-2"
                    style={{
                        backgroundColor: '#6c757d',
                        color: 'white',
                        marginBottom: '30px',
                    }}
                >
                    <h5 style={{ fontSize: 'bold' }}>Email</h5>
                </div>
                {/* Astrologer Email */}
                <InputField
                    label="Email"
                    type="email"
                    name="email"
                    formik={formik}
                />
                {/* <div
                    className="col-lg-12 p-2"
                    style={{
                        backgroundColor: '#6c757d',
                        color: 'white',
                        marginBottom: '30px',
                    }}
                >
                    <h5>Astrologer Validation</h5>
                </div> */}
                {/* Astrologer Validation */}

                {/* <InputField
                    label="Varified"
                    type="text"
                    name="verified"
                    formik={formik}  
                /> */}
                {/* <InputField name="verified" value="Varified" formik={formik} style={{border: 'none', borderRadius: '4px', background:'#ffc107'}}>
                    <option value="Varified">Varified</option>
                    <option value="Unvarified">Unvarified</option>
                </InputField> */}
                <div
                    className="col-lg-12 p-2"
                    style={{
                        backgroundColor: '#6c757d',
                        color: 'white',
                        marginBottom: '30px',
                    }}
                >
                    <h5>Address</h5>
                </div>

                {/* Address details */}
                <InputField
                    label="Address/Locality"
                    type="text"
                    name="locality"
                    formik={formik}
                />
                <InputField
                    label="City"
                    type="text"
                    name="city"
                    formik={formik}
                />
                <InputField
                    label="State"
                    type="text"
                    name="state"
                    formik={formik}
                />
                <InputField
                    label="Country"
                    type="text"
                    name="country"
                    formik={formik}
                />
                <InputField
                    label="Pincode"
                    type="number"
                    name="pinCode"
                    formik={formik}
                />
                {/* <div className="col-lg-6"></div> */}
            </div>
            <div className="row row-bg-3">
                <div
                    className="col-lg-12 p-2"
                    style={{
                        backgroundColor: '#6c757d',
                        color: 'white',
                        marginBottom: '30px',
                    }}
                >
                    <h5>Price Information</h5>
                </div>
                {/* Rate/Price details */}
                <InputField
                    label="Chat Price"
                    type="number"
                    name="chatPrice"
                    formik={formik}
                />
                <InputField
                    label="Call Price"
                    type="number"
                    name="audioCallPrice"
                    formik={formik}
                />
                <InputField
                    label="Report Price"
                    type="number"
                    name="reportPrice"
                    formik={formik}
                />
                <InputField
                    label="Experience"
                    type="number"
                    name="experience"
                    formik={formik}
                />
            </div>

            <div className="row row-bg-5">
                <div
                    className="col-lg-12 p-2"
                    style={{
                        backgroundColor: '#6c757d',
                        color: 'white',
                        marginBottom: '30px',
                    }}
                >
                    <h5>Account Information</h5>
                </div>
                {/* Bank details */}
                <InputField
                    label="Bank Name"
                    type="text"
                    name="bankName"
                    formik={formik}
                />
                <InputField
                    label="Account Number"
                    type="number"
                    name="accNumber"
                    formik={formik}
                />
                <InputField
                    label="Bank IFSC"
                    type="text"
                    name="ifsc"
                    formik={formik}
                />
            </div>
            <div className="submit-btn">
                <button type="submit" className="btn btn-info mb-2">
                    Add Astrologer
                </button>
            </div>
        </form>
    );
}

export default Form;
