import React, { useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import Message from './Message';

const MessageBox = ({ messages }) => {
    useEffect(() => scrollToBottom(), [messages]);

    function scrollToBottom() {
        animateScroll.scrollToBottom({
            containerId: 'scrolly',
        });
    }
    return (
        <div className="messages" id="scrolly">
            <ul>
                {messages.map((message, index) => (
                    <div key={index}>
                        <Message message={message} index={index} />
                    </div>
                ))}
            </ul>
        </div>
    );
};

export default MessageBox;
