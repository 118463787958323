import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../Styles/Admin.module.css'
import moment from 'moment'


const url = process.env.REACT_APP_BASE_API

const Index = () => {
    const [astroOnlineOffineActivity, setastroOnlineOffineActivity] = useState();
    const { location } = useHistory()
    const astroOnOffActivity = location.state.astroOnlineOffineActivity;
    const astro = location.state.astro

    const style = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px'
    }
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <div style={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '5px' }}>
                    <h4 style={{ textAlign: 'center' }}> {astro && astro} : Astro Online Offine Activity </h4>
                    <div style={style}>
                        <div style={{ width: '50%' }}>
                        </div>
                    </div>
                    <div className={styles.onOff}>
                        <table
                            class="table table-striped table-bordered" l
                            style={{
                                border: '1px solid gray',
                                borderRadius: '3px',
                                backgroundColor: 'white',
                            }}
                        >
                            <thead className={styles.onOff1}>
                                <tr>
                                    <th>+</th>
                                    <th>online Time</th>
                                    <th>Offline Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {astroOnOffActivity.rows && astroOnOffActivity.rows.length > 0 && astroOnOffActivity.rows.map((data, index) => {
                                    return (data.offlineTime ? <tr key={index}>
                                        <td><strong>{"=>"}</strong></td>
                                        <td>{moment(data.createdAt).format('llll')}</td>
                                        <td>{moment(data.updatedAt).format('llll')}</td>
                                    </tr> : "")
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;
