import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../Styles/Admin.module.css'
import moment from 'moment'


const url = process.env.REACT_APP_BASE_API

const Index = () => {
    const [callDetails, setcallDetails] = useState();
    const { location } = useHistory()
    const callDetail = location.state.callDetails;
    const astro = location.state.astro

    const style = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px'
    }
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <div style={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '5px' }}>
                    <h4 style={{ textAlign: 'center' }}>{astro && astro} : All Call Details </h4>
                    <div style={style}>
                        <div style={{ width: '50%' }}>
                        </div>
                    </div>
                    <div className={styles.astroView}>
                        <table
                            class="table table-striped table-bordered" l
                            style={{
                                border: '1px solid gray',
                                borderRadius: '3px',
                                backgroundColor: 'white',
                            }}
                        >
                            <thead className={styles.astroView1}>
                                <tr>
                                    <th>S.No.</th>
                                    <th>User Name & Mobile No</th>
                                    <th>Call Rate</th>
                                    <th>Amount Charged</th>
                                    <th>Call Duration</th>
                                    <th>Total Call Duration</th>
                                    <th>Call Time</th>
                                    <th>Call Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {callDetail.rows && callDetail.rows.length > 0 && callDetail.rows.map((data, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.user.name + "/" + data.user.mobile}</td>
                                        <td>{data.callRate}</td>
                                        <td>{data.amountCharged}</td>
                                        <td>{data.callDuration}</td>
                                        <td>{data.totalCallDuration}</td>
                                        <td>{moment(data.kw_callTime, 'HH:mm:ss').add(data.kw_callDate, 'minutes').format('HH:mm:ss').toLocaleString()}</td>
                                        <td>{moment(data.kw_callDate).format('llll')}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;
