import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { astroLogin } from '../../store/actions/astroAuth';

const AstrologerLogin = (props) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.user);
    const referer = props.location.state ? props.location.state.from : null;
    const formik = useFormik({
        initialValues: { email: '', password: '' },
        onSubmit: (values) => {
            dispatch(astroLogin(values, referer));
        },
        validationSchema: () =>
            Yup.object().shape({
                email: Yup.string()
                    .required('Email is required.')
                    .email('Enter the valid email.'),
                password: Yup.string()
                    .required('Password is required.')
                    .min(8, 'Password should be 8 or more character.'),
            }),
    });
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="login100-form validate-form"
                    >
                        <span className="login100-form-title p-b-26">
                            Astrologer Login
                        </span>
                        {data.isError && (
                            <div style={{ marginBottom: '15px', color: 'red' }}>
                                {data.errorMessage}
                            </div>
                        )}
                        <div className="wrap-input100 validate-input">
                            <input
                                className="input100"
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </div>

                        {formik.errors.email && formik.touched.email && (
                            <div className="input-error">
                                {formik.errors.email}
                            </div>
                        )}

                        <div className="wrap-input100 validate-input">
                            <span className="btn-show-pass">
                                <i className="zmdi zmdi-eye"></i>
                            </span>
                            <input
                                className="input100"
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formik.values.password}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </div>
                        {formik.errors.password && formik.touched.password && (
                            <div className="input-error">
                                {formik.errors.password}
                            </div>
                        )}

                        {/* <div className="wrap-input1002">
                            <div className="checkbox">
                                <label>
                                    <input name="agreement" type="checkbox" /> I
                                    agree to
                                    <a
                                        href="/Astrologer/Account/termsandconditions"
                                        target="_blank"
                                    >
                                        Terms and Conditions
                                    </a>
                                </label>
                            </div>
                        </div> */}

                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button
                                    type="submit"
                                    className="login100-form-btn"
                                >
                                    Login
                                </button>
                            </div>
                        </div>

                        <div className="text-center p-t-115">
                            <span className="txt3">
                                <Link to="/forgot-password">
                                    Forget Your Password?
                                </Link>
                            </span>

                            <span className="txt1">Don’t have an account?</span>

                            <Link to="/signup" className="txt2">
                                Sign Up
                            </Link>
                            {/* <p><span className="txt2">Login as </span>
                            <Link to="/admin/login" className="txt2">
                                Admin
                            </Link></p> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AstrologerLogin;




// "proxy": "https://beta.jyotirvid.in",