import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { adminLogin } from '../../store/actions/adminAuth';

const AdminLogin = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.admin);

    const formik = useFormik({
        initialValues: { email: '',password: '' },
        onSubmit: (values) => {
            dispatch(adminLogin(values));
        },
        validationSchema: () =>
            Yup.object().shape({
                email: Yup.string()
                    .required('Please enter the user id.')
                    .min(4, 'Please enter a valid user id.'),
                password: Yup.string()
                    .required('Password in required.')
                    .min(5, 'Please enter a valid password'),
            }),
    });
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="login100-form validate-form"
                    >
                        <span className="login100-form-title p-b-26">
                            Admin Login
                        </span>
                        {data.isError && (
                            <div style={{ marginBottom: '15px', color: 'red' }}>
                                {data.error}
                            </div>
                        )}
                        <div className="wrap-input100 validate-input">
                            <input
                                className="input100"
                                type="text"
                                placeholder="Email ID"
                                name="email"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </div>
                        {formik.errors.email && formik.touched.email && (
                            <div className="input-error">
                                {formik.errors.email}
                            </div>
                        )}

                        <div className="wrap-input100 validate-input">
                            <span className="btn-show-pass">
                                <i className="zmdi zmdi-eye"></i>
                            </span>
                            <input
                                className="input100"
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formik.values.password}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </div>
                        {formik.errors.password && formik.touched.password && (
                            <div className="input-error">
                                {formik.errors.password}
                            </div>
                        )}

                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button
                                    type="Submit"
                                    className="login100-form-btn"
                                >
                                    Login
                                </button>
                            </div>
                        </div>

                        <div className="text-center p-t-115">
                            <Link to="/login" className="txt2" href="#">
                                Sign In as Astrologer
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
