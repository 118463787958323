import React, { useState, useEffect, Fragment } from 'react';
import { useFormik } from "formik";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';
import styles from '../../../Styles/Admin.module.css'
import moment from 'moment'



const Index = () => {
  const { location } = useHistory()
  const accountDetails = location.state.account
  const startDate = location.state.startDate
  const endDate = location.state.endDate
  const formik = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
    },
  });
  return (
    <>
      <div style={{ padding: '10px' }}>
        <Form
          onSubmit={formik.handleSubmit} >
          <Row>
            <Col md={2} >
              <Input
                type="date"
                name="startDate"
                value={formik.values.startDate}
              />
            </Col>
            <Col md={2}>
              <Input
                type="date"
                name="endDate"
                value={formik.values.endDate}
              />
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
        <div style={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '5px' }}>
          <h4 style={{ textAlign: 'center' }}>Jyotirvid User Acoount</h4>
          
          <div className={styles.callMissed}>
            <table
              class="table table-striped table-bordered" l
              style={{
                border: '1px solid gray',
                borderRadius: '3px',
                backgroundColor: 'white',
              }}
            >
              <thead className={styles.astroView1}>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>E-Mail - Mobile No.</th>
                  <th>Wallet</th>
                  <th>Joining Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {accountDetails && accountDetails.length > 0 ? accountDetails.map((data, index) => {
                  return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.email + "/" + data.mobile}</td>
                    <td>{data.wallet}</td>
                    <td>{moment(data.createdAt).format('llll')}</td>
                  </tr>
                }) : (
                  <p>*There isn't present any data yet.</p>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
export default Index
