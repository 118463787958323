import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

const url = process.env.REACT_APP_BASE_API;

const ResetPassword = (props) => {
    const history = useHistory();
    const params = useParams();
    
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    
    const resetpassword = async (formData) => {
        try {
            const { data } = await axios({
                method: 'patch',
                baseURL: url,
                url: '/account/resetPassword/' + params.resetToken,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: { password: formData },
            });

            if (data.status === 'success') {
                setIsSuccess(true);
                setIsError(false);
                setMessage(
                    'Your password changed successfully.You will be redirected to login page shortly.'
                );
                formik.setSubmitting(false);
                setTimeout(() => history.push('/login'), 2000);
            }
            if (data.status === 'fail') {
                setIsError(true);
                setError(data.message);
                formik.setSubmitting(false);
            }
        } catch (err) {
            if (err.status === 429) {
                setIsError(true);
                setError(
                    'Too many request from this IP. Try again in an hour.'
                );
                setIsError(true);
            }
            if (err.response) {
                setIsError(true);
                setError('Something went wrong! Try again later.');
            } else if (err.request) {
                setIsError(true);
                setError('Something went wrong! Try again later.');
            } else {
                setIsError(true);
                setError('Something went wrong! Try again later.');
            }
        }
    };

    const formik = useFormik({
        initialValues: { newPassword: '', confirmPassword: '' },
        onSubmit: (values) => {
            resetpassword(values.newPassword);
        },
        validationSchema: () =>
            Yup.object().shape({
                newPassword: Yup.string()
                    .required('Required.')
                    .min(8, 'Password should be 8 or more character.'),
                confirmPassword: Yup.string()
                    .required('Required')
                    .oneOf(
                        [Yup.ref('newPassword'), null],
                        'Passwords must match'
                    ),
            }),
    });
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="login100-form validate-form"
                    >
                        <span className="login100-form-title p-b-26">
                            Reset Your Password
                        </span>
                        {isError && (
                            <div style={{ marginBottom: '15px', color: 'red' }}>
                                {error}
                            </div>
                        )}

                        {isSuccess && (
                            <div className={'alert alert-success'}>
                                {message}
                            </div>
                        )}

                        <div className="wrap-input100 validate-input">
                            <input
                                className="input100"
                                type="password"
                                placeholder="New Password"
                                name="newPassword"
                                value={formik.values.newPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </div>
                        {formik.errors.newPassword &&
                            formik.touched.newPassword && (
                                <div className="input-error">
                                    {formik.errors.newPassword}
                                </div>
                            )}

                        <div className="wrap-input100 validate-input">
                            <span className="btn-show-pass">
                                <i className="zmdi zmdi-eye"></i>
                            </span>
                            <input
                                className="input100"
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={formik.values.confirmPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </div>
                        {formik.errors.confirmPassword &&
                            formik.touched.confirmPassword && (
                                <div className="input-error">
                                    {formik.errors.confirmPassword}
                                </div>
                            )}

                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button
                                    type="submit"
                                    className="login100-form-btn"
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="text-center p-t-115">
                        <span className="txt3">
                            <Link to="/login">Back to Login Page</Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
