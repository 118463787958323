import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function Input({ name, type, placeholder, label, ...props }) {
    return (
        <Field name={name}>
            {({ field }) => (
                <div className="col-lg-6">
                    <div className="form-group">
                        <div className="row">
                            {label ? (
                                <label className="col-lg-4 col-form-label">
                                    {label}
                                </label>
                            ) : (
                                ''
                            )}
                            <div className="col-lg-8">
                                <input
                                    {...props}
                                    {...field}
                                    type={type}
                                    placeholder={
                                        placeholder ? placeholder : label
                                    }
                                    className="form-control"
                                />
                                <ErrorMessage
                                    style={{ color: 'red' }}
                                    component="div"
                                    name={name}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Field>
    );
}

const validationSchema = Yup.object().shape({
    // image: Yup.mixed().required(),
});

function Form(props) {
    const initialValues = {
        name: props.name || '',
        email: props.email || '',
        mobile: props.mobile || '',
        gender: props.gender || 'male',
        image: props.image || null,
    };
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
                props.handleFormSubmit(values);
            }}
            validationSchema={validationSchema}
        >
            {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                    <div className="row row-bg-1">
                        <div className="col-lg-12">
                            <h5>Basic details</h5>
                        </div>
                        {/* Basic profile details */}
                        <Input
                            label="Full Name"
                            type="text"
                            name="name"
                            placeholder="Enter your Full Name"
                        />

                        <div className="col-lg-6">
                            <div className="form-group">
                                <div className="row">
                                    <label className="col-lg-4 col-form-label">
                                        Gender
                                    </label>
                                    <div className="col-lg-8">
                                        <Field
                                            name="gender"
                                            as="select"
                                            placeholder="Gender"
                                            className="form-control"
                                            value={formik.values.gender}
                                        >
                                            <option value="male">Male</option>
                                            <option value="female">
                                                Female
                                            </option>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Input
                            label="Email Id"
                            type="email"
                            name="email"
                            disabled
                        />
                        <Input
                            label="Mobile Number"
                            type="number"
                            name="mobile"
                            disabled
                        />

                        {/* Image upload */}
                        <div className="col-lg-6">
                            <div className="form-group">
                                <div className="row">
                                    <label
                                        htmlFor="file"
                                        className="col-lg-4 col-form-label"
                                    >
                                        Profile Image
                                    </label>
                                    <div className="col-lg-8">
                                        <input
                                            id="file"
                                            name="image"
                                            type="file"
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'image',
                                                    event.currentTarget.files[0]
                                                );
                                            }}
                                            className="form-control"
                                        />
                                        <ErrorMessage
                                            style={{ color: 'red' }}
                                            component="div"
                                            name="image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6"></div>
                    </div>

                    <div className="submit-btn">
                        <button type="submit" className="btn btn-info mb-2">
                            Update
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default Form;
