import { createStore, applyMiddleware } from 'redux';
import Thunk from 'redux-thunk';
import logger from 'redux-logger';

import Reducer from './reducers';
const createStoreWithMiddleware = applyMiddleware(Thunk, logger)(createStore);
const store = createStoreWithMiddleware(
    Reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
