import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { astroSignUp } from '../../store/actions/astroAuth';

const AstrologerSignup = () => {
    const mobileRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const dispatch = useDispatch();
    const data = useSelector((state) => state.user);

    const formik = useFormik({
        initialValues: { name: '', email: '', mobile: '', password: '' },
        onSubmit: (values) => {
            dispatch(astroSignUp(values));
        },
        validationSchema: () =>
            Yup.object().shape({
                name: Yup.string()
                    .required('Name is required.')
                    .min(3, 'Enter your full name.'),
                email: Yup.string()
                    .required('Email is required.')
                    .email('Enter a valid email.'),
                password: Yup.string()
                    .required('Password is required.')
                    .min(8, 'Password must be 8 or more chracter.'),
                mobile: Yup.string()
                    .required('Mobile numer is required.')
                    .matches(mobileRegExp, 'Enter a valid mobile number.')
                    .min(10, 'Enter a valid Number'),
            }),
    });
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form
                        onSubmit={formik.handleSubmit}
                        className="login100-form validate-form"
                        autoComplete="off"
                    >
                        <span className="login100-form-title p-b-26">
                            Astrologer Signup
                        </span>
                        {data.isError && (
                            <div style={{ marginBottom: '15px', color: 'red' }}>
                                {data.errorMessage}
                            </div>
                        )}
                        <div className="wrap-input100 validate-input">
                            <input
                                className="input100"
                                type="text"
                                placeholder="Full Name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <span className="focus-input100"></span>
                        </div>
                        {formik.errors.name && formik.touched.name && (
                            <div className="input-error">
                                {formik.errors.name}
                            </div>
                        )}

                        <div className="wrap-input100 validate-input">
                            <input
                                className="input100"
                                type="text"
                                placeholder="Email Id"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <span className="focus-input100"></span>
                        </div>
                        {formik.errors.email && formik.touched.email && (
                            <div className="input-error">
                                {formik.errors.email}
                            </div>
                        )}

                        <div className="wrap-input100 validate-input">
                            <input
                                className="input100"
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <span className="focus-input100"></span>
                        </div>
                        {formik.errors.password && formik.touched.password && (
                            <div className="input-error">
                                {formik.errors.password}
                            </div>
                        )}

                        <div className="wrap-input-100">
                            <select
                                id="cars"
                                className="wrap-input101"
                                defaultValue="volvo"
                            >
                                <option value="volvo">+ 91</option>
                                {/* <option value="saab">+ 9123</option>
                                <option value="opel">+ 9144</option>
                                <option value="audi">+ 055</option> */}
                            </select>
                            <div className="wrap-input100 validate-input wrap-input102">
                                <input
                                    className="input100"
                                    type="text"
                                    placeholder="Mobile Number"
                                    name="mobile"
                                    value={formik.values.mobile}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <span className="focus-input100"></span>
                            </div>
                        </div>
                        {formik.errors.mobile && formik.touched.mobile && (
                            <div className="input-error">
                                {formik.errors.mobile}
                            </div>
                        )}

                        {/* <div className="wrap-input1002">
                            <div className="checkbox">
                                <label>
                                    <input name="agreement" type="checkbox" /> I
                                    agree to
                                    <a
                                        href="/Astrologer/Account/termsandconditions"
                                        target="_blank"
                                    >
                                        Terms and Conditions
                                    </a>
                                </label>
                            </div>
                        </div> */}

                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button
                                    type="submit"
                                    className="login100-form-btn"
                                >
                                    Sign Up
                                </button>
                            </div>
                        </div>

                        <div className="text-center p-t-115">
                            <span className="txt1">
                                Already have an account?
                            </span>

                            <Link to="/login" className="txt2">
                                Login
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AstrologerSignup;
