import React, { useState, useEffect } from 'react';
import { useFormik } from "formik";
import { Nav, NavItem, NavLink, Button, Form, FormGroup, Input, Row, Col, Card, Label } from "reactstrap";
import axios from 'axios';
import styles from '../Admin/Styles/Admin.module.css'
import { useHistory, Link } from 'react-router-dom';

const url = process.env.REACT_APP_BASE_API

const Dashboard = () => {
    const today = new Date();
    const navigate = useHistory();
    const [chatHistory, setChatHistory] = useState([]);
    const [chatHistor, setChatHistor] = useState([]);
    const [astroChatMissedList, setAstroChatMissedList] = useState([])
    const [newUserList, setNewUserList] = useState([])
    const [rechargeList, setRechargeList] = useState([])
    const [totalConnectedChats, setTotalConnectedChats] = useState([])
    const [userChatMissedList, setUserChatMissedList] = useState([])
    const [verifyedUsersList, setVerifyedUsersList] = useState([])
    const [unVerifyedUsersList, setUnVerifyedUsersList] = useState([])
    const [totalCallRequestCount, setTotalCallRequestCount] = useState('')
    const [totalCallMissedCount, setTotalCallMissedCount] = useState('')
    const [totalConnectedCalls, setTotalConnectedCalls] = useState([])
    const [userCallMissedList, setUserCallMissedList] = useState([])
    const [astroCallMissedList, setAstroCallMissedList] = useState([])
    const [jyotirvidEarning, setjyotirvidEarning] = useState('')
    const [totalChatRequestCount, setTotalChatRequestCount] = useState('')
    const [totalChatMissedCount, setTotalChatMissedCount] = useState('')
    const [rechargeAmount, setRechargeAmount] = useState('')
    const [loader, setLoader] = useState(false)
    const [displayMsg, setDisplayMsg] = useState(false)

    //    --------------------------- video call state ---------------------
    const [totalVideoCallRequestCount, settotalVideoCallRequestCount] = useState('')
    const [totalVideoCallMissedCount, settotalVideoCallMissedCount] = useState('')
    const [totalConnectedVideoCalls, settotalConnectedVideoCalls] = useState([])
    const [userVideoCallMissedList, setuserVideoCallMissedList] = useState([])
    const [astroVideoCallMissedList, setastroVideoCallMissedList] = useState([])
    let totalChatCountSum = 0;
    let totalChatCountSu = 0;

    const getChatHistroy = async () => {
        const res = await axios.get(url + '/chat/user', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setChatHistory([...chatHistory, ...res.data.chatRooms]);
        }
    };

    const getChatHistro = async () => {
        const res = await axios.get(url + '/chat/getUsers', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setChatHistor([...chatHistor, ...res.data.chatRooms]);
        }
    };

    const ApiCall = async (data) => {
        try {
            const res = await axios(url + `/admin/astrologer-day-list-date?fromDate=${data.startDate}&toDate=${data.endDate}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + localStorage.getItem('token')
                },
                method: "get",
            });
            if (res.data.status == 'sucess') {
                setAstroChatMissedList(res.data.data.astroChatMissedList)
                setNewUserList(res.data.data.newUserList)
                setRechargeList(res.data.data.rechargeList)
                setTotalConnectedChats(res.data.data.totalConnectedChats)
                setUserChatMissedList(res.data.data.userChatMissedList)
                setVerifyedUsersList(res.data.data.verifyedUsersList)
                setTotalChatRequestCount(res.data.data.totalChatRequestCount)
                setTotalChatMissedCount(res.data.data.totalChatMissedCount)
                setjyotirvidEarning(res.data.data.jyotirvidEarning)
                setRechargeAmount(res.data.data.rechargeAmount)
                setUnVerifyedUsersList(res.data.data.unVerifyedUsersList)
                setTotalCallRequestCount(res.data.data.totalCallRequestCount)
                setTotalCallMissedCount(res.data.data.totalCallMissedCount)
                setTotalConnectedCalls(res.data.data.totalConnectedCalls)
                setUserCallMissedList(res.data.data.userCallMissedList)
                setAstroCallMissedList(res.data.data.astroCallMissedList)

                // ------------------------- video call data fatch API-------------------
                settotalVideoCallRequestCount(res.data.data.totalVideoCallRequestCount)
                settotalVideoCallMissedCount(res.data.data.totalVideoCallMissedCount)
                settotalConnectedVideoCalls(res.data.data.totalConnectedVideoCalls)
                setuserVideoCallMissedList(res.data.data.userVideoCallMissedList)
                setastroVideoCallMissedList(res.data.data.astroVideoCallMissedList)
                setLoader(false)
            }
        }
        catch (err) {
            console.log('Error', err)
            if (err == 'Error: Request failed with status code 403') navigate.goBack()
        }
    }
    const HandleRedirectReachargeList = (values) => {
        navigate.push({
            pathname: '/admin/stats-total-recharge-list',
            state: {
                startDate: formik.values.startDate,
                endDate: formik.values.endDate,
                rechargeDetailsList: values
            }
        })
    }
    const HandleRedirectAccount = (values) => {
        navigate.push({
            pathname: '/admin/stats-new-user-list-account',
            state: {
                account: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectVerifiedAccount = (values) => {
        navigate.push({
            pathname: '/admin/stats-verified-account',
            state: {
                verifiedAccount: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectUnverifiedAccount = (values) => {
        navigate.push({
            pathname: '/admin/stats-unverified-user-account',
            state: {
                unverifiedAccountData: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectastroChatMissedList = (values) => {
        navigate.push({
            pathname: '/admin/stats-astrologer-chat-missed',
            state: {
                astroChatMissedList: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectconnectedCalls = (values) => {
        navigate.push({
            pathname: '/admin/stats-connected-calls',
            state: {
                connectedCall: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectUserCallMissedCount = (values) => {
        navigate.push({
            pathname: '/admin/stats-user-call-missed',
            state: {
                userCallMissed: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectastroCallMissedList = (values) => {
        navigate.push({
            pathname: '/admin/stats-astrologer-call-missed',
            state: {
                astroCallMissedList: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectUserChatMissed = (values) => {
        navigate.push({
            pathname: '/admin/stats-user-chat-missed',
            state: {
                chatMissedByUser: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectChatConnected = (values) => {
        navigate.push({
            pathname: '/admin/stats-chat-connected',
            state: {
                chatConnectedData: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }

    // ---------------------------video call HandleRedirect -*-------------------
    const HandleRedirectVideoCallconnected = (values) => {
        navigate.push({
            pathname: '/admin/stats-video-call-connected',
            state: {
                totalConnectedVideoCalls: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectUserVideoCallMissedCount = (values) => {
        navigate.push({
            pathname: '/admin/stats-user-video-call-missed',
            state: {
                userVideoCallMissedList: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const HandleRedirectastroVideoCallMissedList = (values) => {
        navigate.push({
            pathname: '/admin/stats-astrologer-video-call-missed',
            state: {
                astroVideoCallMissedList: values,
                startDate: formik.values.startDate,
                endDate: formik.values.endDate
            }
        })
    }
    const formik = useFormik({
        initialValues: {
            startDate: today.getFullYear() + '-' + ((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1) + '-' +
                parseInt(today.getDate() - 1),
            endDate: today.getFullYear() + '-' + ((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + parseInt(today.getDate()),
        },
        onSubmit: (values) => {
            if (formik.values.startDate == formik.values.endDate) {
                setDisplayMsg(true)
            }
            else {
                setDisplayMsg(false)
                ApiCall(values)
                setLoader(true)
            }
        },
    });
    useEffect(() => {
        ApiCall({
            startDate: formik.values.startDate,
            endDate: formik.values.endDate,
        })
        getChatHistroy();
        getChatHistro();
    }, [])

    return (
        <div >
            <div className={styles.stats}>
                <h2 style={{ textAlign: 'center' }}>Admin Dasbhboard </h2>
                <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                    <Form
                        onSubmit={formik.handleSubmit} >
                        <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <Col md={3} >
                                <Label>Start Date: </Label>
                                <Input
                                    type="date"
                                    placeholder="Enter your Start Date..."
                                    name="startDate"
                                    max={today.getFullYear() + '-' + (today.getMonth() < 10 ? '0' : '') + parseInt(today.getMonth() + 1) + '-' + parseInt(today.getDate() - 1)}
                                    value={formik.values.startDate}
                                    onChange={formik.handleChange} />
                            </Col>
                            <Col md={3}>
                                <Label>End Date: </Label>
                                <Input
                                    type="date"
                                    placeholder="Enter your End Date..."
                                    name="endDate"
                                    min={formik.values.startDate}
                                    max={today.getFullYear() + '-' + (today.getMonth() < 10 ? '0' : '') + parseInt(today.getMonth() + 1) + '-' + parseInt(today.getDate())}
                                    value={formik.values.endDate}
                                    onChange={formik.handleChange}
                                />
                            </Col>
                            <Col md={2}>
                                <FormGroup style={{ paddingTop: '32px' }}>
                                    <Button className="btn" type="submit">
                                        Submit
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    {displayMsg && <div style={{ color: 'red' }}>*Start Date cannot be greater than or equal to End Date</div>}
                </div>
                <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                    <div className="col-md-6" style={{ marginTop: '20px' }}>
                        <Card body className="border-left-warning card-cust">
                            <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <div style={{ hight: '100px', width: '100%' }}>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', color: 'orange', marginLeft: '60px' }}><h4>Total Astrologer</h4></div>
                                        <div style={{ width: '30%', height: 'fit-content', textAlign: 'center', }}><div className="icons" >
                                            <i
                                                className="fa fa-users"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                        <div style={{ width: '270px' }}>
                                            <Nav>
                                                <NavItem >
                                                    <NavLink tag={Link} to={'/admin/astro'} style={{ fontWeight: '600', fontSize: '20px', padding: '0px' }}>Astrologers</NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <div><b style={{ width: '10px' }}>:</b></div>
                                        <div style={{ width: '100px', textAlign: 'center' }}>
                                            {chatHistory && chatHistory.length > 0 ? (chatHistory.map((data) => {
                                                {
                                                    (() => { totalChatCountSum++; })();
                                                }
                                                return (
                                                    <tr>{/* <td></td> */}</tr>
                                                );
                                            })
                                            ) : ''}
                                            {totalChatCountSum}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-6" style={{ marginTop: '20px' }}>
                        <Card body className="border-left-warning card-cust">
                            <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <div style={{ hight: '100px', width: '100%' }}>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', color: 'orange', marginLeft: '60px' }}><h4>Total Users</h4></div>
                                        <div style={{ width: '30%', height: 'fit-content', textAlign: 'center', }}><div className="icons" >
                                            <i
                                                className="fa fa-user"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                        <div style={{ width: '270px' }}>
                                            <Nav>
                                                <NavItem>
                                                    <NavLink tag={Link} to={'/admin/users'} style={{ fontWeight: '600', fontSize: '20px', padding: '0px' }}>Users</NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <div><b style={{ width: '10px' }}>:</b></div>
                                        <div style={{ width: '100px', textAlign: 'center' }}>
                                            {chatHistor && chatHistor.length > 0 ? (chatHistor.map((data) => {
                                                {
                                                    (() => { totalChatCountSu++; })();
                                                }
                                                return (
                                                    <tr>{/* <td></td> */}</tr>
                                                );
                                            })) : ''}

                                            {totalChatCountSu}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Card>
                    </div>
                </div>
                <section>
                    {
                        loader ?
                            <div style={{ width: 'fit-content', margin: 'auto', display: 'flex', paddingTop: '200px' }}><div class={styles.clockLoader}></div>
                                <div class={styles.loader}>Loading...</div>
                            </div>
                            :
                            <div>
                                <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                                    <div className="col-md-6" style={{ marginTop: '20px' }}>
                                        <Card body className="border-left-warning card-cust">
                                            <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                <div style={{ hight: '100px', width: '100%' }}>
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', color: 'orange', marginLeft: '60px' }}><h4>User Account Details</h4></div>
                                                        <div style={{ width: '30%', height: 'fit-content', textAlign: 'center', }}><div className="icons" >
                                                            <i
                                                                className="fa fa-user"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div></div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectAccount(newUserList) }} className={styles.button}><h5>Account</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{newUserList && newUserList.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectVerifiedAccount(verifyedUsersList) }} className={styles.button}><h5>Verified Account </h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{verifyedUsersList && verifyedUsersList.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectUnverifiedAccount(unVerifyedUsersList) }} className={styles.button}><h5>Unverified Account</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{newUserList && (newUserList.length - verifyedUsersList.length)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '20px' }}>
                                        <Card body className="border-left-warning card-cust">
                                            <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                <div style={{ hight: '100px', width: '100%' }}>
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', color: 'orange', marginLeft: '60px' }}><h4>Recharge list</h4></div>
                                                        <div style={{ width: '17%', height: 'fit-content', }}><div className="icons" >
                                                            <i
                                                                className="fa fa-user"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div></div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectReachargeList(rechargeList) }} className={styles.button}><h5>Recharge list</h5></b></div>
                                                            <div ><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{rechargeList && rechargeList.count}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b><h5>Recharge Amount</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{rechargeAmount && '₹' + rechargeAmount}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b ><h5>Life Time Jyotirvid Earning</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{jyotirvidEarning && '₹' + jyotirvidEarning}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                
                                <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                                    {/* ------------------- video call Box ------------------ */}
                                    <div className="col-md-6" style={{ marginTop: '20px' }}>
                                        <Card body className="border-left-warning card-cust">
                                            <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                <div style={{ hight: '100px', width: '100%' }}>
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', color: 'orange', marginLeft: '60px' }}><h4>Vedio Call Details</h4></div>
                                                        <div style={{ width: '30%', height: 'fit-content', textAlign: 'center', }}><div className="icons" >
                                                            <i
                                                                className="fa fa-users"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div></div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b><h5>Call Request</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalVideoCallRequestCount && totalVideoCallRequestCount}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b><h5>Call Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalVideoCallMissedCount && totalVideoCallMissedCount}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectVideoCallconnected(totalConnectedVideoCalls) }} className={styles.button}><h5>Call Connected</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalConnectedVideoCalls && totalConnectedVideoCalls.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectUserVideoCallMissedCount(userVideoCallMissedList) }} className={styles.button}><h5>User Call Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{userVideoCallMissedList && userVideoCallMissedList.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectastroVideoCallMissedList(astroVideoCallMissedList) }} className={styles.button}><h5>Astrologer Call Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{astroVideoCallMissedList && astroVideoCallMissedList.length}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '20px' }}>
                                        <Card body className="border-left-warning card-cust">
                                            <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                <div style={{ hight: '100px', width: '100%' }}>
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', color: 'orange', marginLeft: '60px' }}><h4>Call Details</h4></div>
                                                        <div style={{ width: '30%', height: 'fit-content', textAlign: 'center', }}><div className="icons" >
                                                            <i
                                                                className="fa fa-users"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div></div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b><h5>Call Request</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalCallRequestCount && totalCallRequestCount}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b><h5>Call Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalCallMissedCount && totalCallMissedCount}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectconnectedCalls(totalConnectedCalls) }} className={styles.button}><h5>Call Connected</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalConnectedCalls && totalConnectedCalls.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectUserCallMissedCount(userCallMissedList) }} className={styles.button}><h5>User Call Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{userCallMissedList && userCallMissedList.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectastroCallMissedList(astroCallMissedList) }} className={styles.button}><h5>Astrologer Call Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{astroCallMissedList && astroCallMissedList.length}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '20px' }}>
                                        <Card body className="border-left-warning card-cust">
                                            <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                <div style={{ hight: '100px', width: '100%' }}>
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '90%', height: 'fit-content', textAlign: 'center', color: 'orange', marginLeft: '60px' }}><h4>Chat Details</h4></div>
                                                        <div style={{ width: '30%', height: 'fit-content', textAlign: 'center', }}><div className="icons" >
                                                            <i
                                                                className="fa fa-users"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div></div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b><h5>Chat Request</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalChatRequestCount && totalChatRequestCount}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b><h5>Chat Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalChatMissedCount && totalChatMissedCount}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectChatConnected(totalConnectedChats) }} className={styles.button}><h5>Chat Connected</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{totalConnectedChats && totalConnectedChats.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectUserChatMissed(userChatMissedList) }} className={styles.button}><h5>User Chat Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{userChatMissedList && userChatMissedList.length}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '95%', margin: '15px auto', justifyContent: 'space-between' }}>
                                                            <div style={{ width: '270px' }}><b onClick={() => { HandleRedirectastroChatMissedList(astroChatMissedList) }} className={styles.button}><h5>Astrologer Chat Missed</h5></b></div>
                                                            <div><b style={{ width: '10px' }}>:</b></div>
                                                            <div style={{ width: '100px', textAlign: 'center' }}>{astroChatMissedList && astroChatMissedList.length}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
            </div>
        </div>
    );
};

export default Dashboard;
