import React, { useEffect, useState } from 'react';
import axios from 'axios';


const Astro = () => {
  const [data, setData] = useState();
  const [astroStatus, setastroStatus] = useState();

  const astroData = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_API}/admin/all-astro-status`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      }).then((res) => {
        setData(res.data.data.rows)

      }).catch((err) => {
        console.log('Error', err)
      })
  }
  useEffect(() => {
    astroData();
  }, []);

  return (
    <div>
      <div><button type="Astro status" onclick="alert (mohsin)"><a href="Astro status"> Astro status:</a></button></div>
      <div>
        <table
          class="table table-striped table-bordered"
          style={{
            border: '1px solid gray',
            borderRadius: '3px',
            backgroundColor: 'white',
          }}
        >
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>astroId</th>
              <th>createdAt</th>
              <th>updatedAt</th>
              <th>offlineTime</th>
              <th>onlineTime</th>

            </tr>
          </thead>
          <tbody
            style={{
              overflow: 'scrollY',
            }}
          >
            {data && data.length > 0 ? (
              data.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>{data.astroId}</td>
                    <td>{data.offlineTime}</td>
                    <td>{data.onlineTime}</td>
                    <td>{data.updatedAt}</td>
                    <td>{data.createdAt}</td>
                  </tr>
                );
              })
            ) : (
              <p>There isn't present any data yet.</p>
            )}
          </tbody>
        </table></div>
    </div>
  )
}

export default Astro
