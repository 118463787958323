import axios from 'axios';
import history from '../../helpers/history';
import {
    VASTU_FETCH_SUCCESS,
    VASTU_FETCH_FAILED,
    MUHURAT_FETCH_SUCCESS,
    MUHURAT_FETCH_FAILED,
    HANDMADE_REPORT_FETCH_SUCCESS,
    HANDMADE_REPORT_FETCH_FAILED,
} from './types';

const URL = process.env.REACT_APP_BASE_API;

export { getMuhurat, getVastu, getHandmadeReport };

// function getMuhurat() {
//     return async (dispatch)=> {
//         try {
//             const data = await axios()
//         } catch(err) {}
//     }
// }

function getMuhurat() {
    return async (dispatch) => {
        fetch(URL + '/service/muhurat')
            .then((res) => res.json())
            .then(
                (result) => {
                    dispatch({
                        type: MUHURAT_FETCH_SUCCESS,
                        data: result.muhurat,
                    });
                },
                (error) => {
                    dispatch({
                        type: MUHURAT_FETCH_FAILED,
                        message: 'data not loaded',
                    });
                }
            );
    };
}

function getVastu() {
    return async (dispatch) => {
        fetch(URL + '/service/vastu')
            .then((res) => res.json())
            .then(
                (result) => {
                    dispatch({
                        type: VASTU_FETCH_SUCCESS,
                        data: result.vastu,
                    });
                },
                (error) => {
                    dispatch({
                        type: VASTU_FETCH_FAILED,
                        message: 'data not loaded',
                    });
                }
            );
    };
}

function getHandmadeReport() {
    return async (dispatch) => {
        fetch(URL + '/service/kundli')
            .then((res) => res.json())
            .then(
                (result) => {
                    dispatch({
                        type: HANDMADE_REPORT_FETCH_SUCCESS,
                        data: result.kundli,
                    });
                },
                (error) => {
                    dispatch({
                        type: HANDMADE_REPORT_FETCH_FAILED,
                        message: 'data not loaded',
                    });
                }
            );
    };
}

// function updateStatus(status) {
//     console.log(status);
//     return async (dispatch) => {
//         dispatch({ type: 'UPDATE_STATUS_START' });
//         try {
//             const { data } = await axios({
//                 url: `${URL}/astro/updateStatus`,
//                 method: 'post',
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`,
//                 },
//                 data: { status: status },
//             });

//             if (data.status === 'success') {
//                 dispatch({
//                     type: 'UPDATE_STATUS_SUCCESS',
//                     status: data.data.status,
//                 });
//             }
//             if (data.stauts === 'fail') {
//                 dispatch({ type: 'UPDATE_STATUS_FAIL', payload: data.data });
//             }
//         } catch (err) {
//             console.log('Something went wrong');
//         }
//     };
// }
// function updateBasicDetails(formValues) {
//     return async (dispatch) => {
//         dispatch({ type: BASIC_PROFILE_UPDATE_START });
//         try {
//             const formdata = new FormData();
//             for (let key in formValues) {
//                 formdata.append(key, formValues[key]);
//             }
//             console.log(formdata);
//             const { data } = await axios({
//                 url: `${URL}/user/updateMe`,
//                 method: 'patch',
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`,
//                 },
//                 data: formdata,
//             });
//             if (data.status === 'success') {
//                 console.log(data);
//                 dispatch({ type: BASIC_PROFILE_UPDATE_SUCCESS });
//             }
//         } catch (err) {
//             console.log('Something went wrong');
//         }
//     };
// }
