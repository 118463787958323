import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Admin/Styles/Admin.module.css'

const url = process.env.REACT_APP_BASE_API;

const Users = () => {
    const [chatHistory, setChatHistory] = useState([]);

    const getChatHistroy = async () => {
        const res = await axios.get(url + '/chat/getUsers', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setChatHistory([...chatHistory, ...res.data.chatRooms]);
        }
    };

    useEffect(() => {
        getChatHistroy();
    }, []);

    return (

        <div >
            <div className="dash-wapp">

                <h1 className="heading-tit" style={{ textAlign: 'center', marginTop: '20px' }}>Users</h1>
                <div className="">
                    <div className="" style={{ height: '90vh' }}>
                        <div className={styles.user}>
                            <table
                                class="table table-striped table-bordered"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '3px',
                                    backgroundColor: 'white',
                                }}
                            >
                                <thead >
                                    <tr

                                    >
                                        <th>S.No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        {/* <th>User Name</th> */}
                                        <th>Mobile</th>
                                        <th>Gender</th>
                                        <th>chatId</th>
                                        <th>Wallet</th>
                                        <th>Joining Date</th>
                                    </tr>
                                </thead >
                                <tbody className={styles.user}

                                >
                                    {chatHistory && chatHistory.length > 0 ? (
                                        chatHistory.map((data, index) => {
                                            let d1 = data.createdAt.split('T')
                                            let d2;
                                            d2 = d1[1].split('.000Z')[0]
                                            return (
                                                <tr key={data.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.name}</td>
                                                    <td>
                                                        {data.email}
                                                    </td>
                                                    <td>{data.mobile}</td>
                                                    <td>{data.gender}</td>
                                                    <td>
                                                        {data.chatId}
                                                    </td>
                                                    <td>
                                                        {data.wallet}</td>
                                                    <td>
                                                        {d2 + ' ' + d1[0]}</td>
                                                    {/* <td>
                                                        {(data?.amountCharged *
                                                            40) /
                                                            100}
                                                    </td> */}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <p>*There isn't present any data yet.</p>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;