import React, { useState, useEffect } from 'react';
import { Card, CardTitle, CardText, Input, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { updateStatus } from '../../store/actions/astroProfile';

const url = process.env.REACT_APP_BASE_API;

const Dashboard = (props) => {
    const [status, setStatus] = useState('offline');
    const [chatHistory, setChatHistory] = useState([]);
    const [callHistory, setCallHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    let totalChatCountSum = 0;
    let totalCallCountSum = 0;
    const dispatch = useDispatch();
    const astroStatus = useSelector((state) => state);
    const handleStatus = (event) => {
        setStatus(event.target.value);
    };
    const user = useSelector((state) => state.user.data);

    const getCurrentStatus = async () => {
        const res = await axios.get(url + '/astro/getStatus/astro', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setStatus(res.data.data.status);
        }
    };

    useEffect(() => {
        getCurrentStatus();
    }, []);

    useEffect(() => {
        getChatHistroy();
    }, []);
    const getChatHistroy = async () => {
        const res = await axios.get(url + '/chat/chatRooms', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setChatHistory([...chatHistory, ...res.data.chatRooms]);
        }
    };
    useEffect(() => {
        getCallHistroy();
    }, []);
    const getCallHistroy = async () => {
        const res = await axios.get(url + '/call/history', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.data.status === 'success') {
            setCallHistory([...callHistory, ...res.data.calls]);
        }
    };
    return (
        <div className="dash-wapp">
            <h1 className="heading-tit">Welcome to Jyotirvid </h1>

            <div className="container-fluid">
                <div className="row pb-5">
                    <div className="col-md-6">
                        <Card className="card-cust py-3 px-3">
                            <div className="pb-2">
                                You are currently ({status})
                                {status && status === 'online' ? <span style={{ display: 'inline-block', float: 'right', width: '3%', height: '15px', backgroundColor: 'green', borderRadius: '50%', marginTop: '10px' }}></span> : status === 'offline' ? <span style={{ display: 'inline-block', float: 'right', width: '3%', height: '15px', backgroundColor: 'red', borderRadius: '50%', marginTop: '10px' }}></span> : <span style={{ display: 'inline-block', float: 'right', width: '3%', height: '15px', backgroundColor: 'yellow', borderRadius: '50%', marginTop: '10px' }}></span>}
                            </div>
                            <div className="d-flex d-flex-screen">
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    onChange={handleStatus}
                                >
                                    <option value="">Select</option>
                                    <option value="online">Online</option>
                                    <option value="offline">Offline</option>
                                </Input>
                                <Button
                                    className="ml-3"
                                    // style={{ height: '40px', width: '200px' }}
                                    onClick={() =>
                                        dispatch(updateStatus(status))
                                    }
                                >
                                    Update Status
                                </Button>
                            </div>
                        </Card>
                    </div>
                    {user && user.active === true ? "" : <div className="col-md-6"> <Card className="card-cust py-3 px-3">
                        <span>
                            "We are working on your profile and will get back to you soon. For any further information please do not hesitate to contact us at info@jyotirvid.in".
                            <span style={{ display: 'inline-block', float: 'right', width: '2%', height: '10px', backgroundColor: 'yellow', borderRadius: '50%', marginTop: '10px' }}></span></span>
                    </Card> </div>}

                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Card body className="border-left-warning card-cust">
                            <div className="row">
                                <div className="col-md-9">
                                    <CardTitle>Total earnings</CardTitle>
                                    <CardText>
                                        <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                        ></i>{' '}
                                        {user ? user.wallet : 0}{' '}INR
                                    </CardText>
                                </div>
                                <div className="col-md-3">
                                    <div className="icons">
                                        <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    {/* <div className="col-md-3">
                        <Card body className="border-left-warning card-cust">
                            <div className="row">
                                <div className="col-md-9">
                                    <CardTitle>Total report</CardTitle>
                                    <CardText>0</CardText>
                                </div>
                                <div className="col-md-3">
                                    <div className="icons">
                                        <i
                                            className="fa fa-file"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div> */}
                    <div className="col-md-4">
                        <Card body className="border-left-warning card-cust">
                            <div className="row">
                                <div className="col-md-9">
                                    <CardTitle>Total Chat</CardTitle>
                                    <CardText>
                                        {chatHistory &&
                                            chatHistory.length > 0 ? (
                                            chatHistory.map((data) => {
                                                {
                                                    (() => {
                                                        totalChatCountSum++;
                                                    })();
                                                }
                                                return (
                                                    <tr>{/* <td></td> */}</tr>
                                                );
                                            })
                                        ) : (
                                            <p></p>
                                        )}
                                        {totalChatCountSum}
                                    </CardText>
                                </div>
                                <div className="col-md-3">
                                    <div className="icons">
                                        <i
                                            className="fa fa-comments"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="col-md-4">
                        <Card body className="border-left-warning card-cust">
                            <div className="row">
                                <div className="col-md-9">
                                    <CardTitle>Total Call</CardTitle>
                                    <CardText>
                                        {callHistory &&
                                            callHistory.length > 0 ? (
                                            callHistory.map((data) => {
                                                {
                                                    (() => {
                                                        totalCallCountSum++;
                                                    })();
                                                }
                                                return (
                                                    <tr>{/* <td></td> */}</tr>
                                                );
                                            })
                                        ) : (
                                            <p></p>
                                        )}
                                        {totalCallCountSum}
                                    </CardText>
                                </div>
                                <div className="col-md-3">
                                    <div className="icons">
                                        <i
                                            className="fa fa-phone"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
