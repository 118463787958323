import React from 'react';
const url = process.env.REACT_APP_BASE_API;

const Menu3 = () => {
    return (
        <div className="dash-wapp">
            <h1 className="heading-tit">Contact Us</h1>

            <div className="col-lg-3 col-sm-6 col-xs-6 footer-section-single">
                <div
                    className="footer-educare-widget widget"
                    style={{
                        border: '1px solid gray',
                        borderRadius: '3px',
                        boxShadow: '1px 3px 10px 1px',
                        width: '300px',
                        margin: '20px auto',
                    }}
                >
                    <div
                        className="footer-title-widget"
                        style={{ alignItem: 'center' }}
                    >
                        {/* <h6>CONTACT INFO</h6>s */}
                    </div>
                    <div className="footer-content-widget">
                        <div className="info-list" style={{ padding: '5px' }}>
                            <ul
                                className="list-unstyled"
                                style={{ padding: '5px' }}
                            >
                                <li style={{ padding: '5px' }}>
                                    <label
                                        style={{
                                            fontWeight: '600',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Email :{' '}
                                    </label>
                                    <i className="fa fa-envelope-o"></i>
                                    <a
                                        href="mailto:info@Jyotirvid.in"
                                        style={{
                                            padding: '5px',
                                        }}
                                    >
                                        info@Jyotirvid.in
                                    </a>
                                </li>
                                <li
                                    style={{
                                        padding: '5px',
                                    }}
                                >
                                    <label
                                        style={{
                                            fontWeight: '600',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Mobile :{' '}
                                    </label>
                                    <i className="fa fa-phone"></i>
                                    <a
                                        href={`tel:+91${process.env.REACT_APP_MOBILE}`}
                                        style={{
                                            padding: '5px',
                                        }}
                                    >
                                        P: +91 7428979997
                                    </a>
                                </li>
                                <li style={{ padding: '5px' }}>
                                    <label
                                        style={{
                                            fontWeight: '600',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Location :{' '}
                                    </label>
                                    <div style={{ display: 'inline' }}>
                                        <i className="fa fa-map-marker"></i>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                margin: '1px 2px',
                                            }}
                                        >
                                            519 D, JMD,
                                        </span>
                                        <div>Sector 48, Gurugram</div>
                                        <span style={{ display: 'inline' }}>
                                            Haryana -
                                            122018
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu3;
