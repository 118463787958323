import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Button, NavbarToggler, Collapse, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { adminLogout } from '../../../store/actions/adminAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const url = process.env.REACT_APP_BASE_URL;

export default (props) => {
  const [isOpen, setOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const toggle = () => setOpen(!isOpen)
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Navbar color="light" light className="navbar shadow-sm p-3 mb-3 bg-white rounded" expand="md">
      <Button color="info" onClick={props.toggle}>
        <FontAwesomeIcon icon={faAlignLeft}/>
      </Button>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          {/* <NavItem>
            <NavLink tag={Link} to={'/admin/astrologers'} style={{fontWeight: 'bold'}}>Astrologers</NavLink>
          </NavItem> */}
          {/* <NavItem>
            <NavLink tag={Link} to={'/admin/users'} style={{fontWeight: 'bold'}}>Users</NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              onClick={() => dispatch(adminLogout())}
              style={{ cursor: 'pointer', fontWeight: 'bold', fontWeight: '600', fontSize: '18px' }}
            ><i className="fa fa-sign-out-alt"></i>logout
            </NavLink>
          </NavItem>
          {/* <NavItem>
                        <NavLink>
                            <Dropdown
                                // className="drop"
                                direction="down"
                                isOpen={dropdownOpen}
                                toggle={toggleDropdown}
                            >
                                <DropdownToggle caret>
                                <img
                                            className="rounded-circle rounded-circle-avt rounded-circle-avts rounded-circle-avts-ct"
                                            alt="User"
                                            src="../../../assets/images/user-default-avatar.png"
                                    />
                                </DropdownToggle>
            <DropdownMenu right>
            <DropdownItem
                                        tag={Link}
                                        to={'/astrologer/dashboard/'}
                                    >
                                        <img
                                            className="rounded-circle rounded-circle-avt rounded-circle-avts rounded-circle-avts-ct"
                                            alt="User"
                                            src={
                                              user
                                                  ? `${url}/images/users/${user.image}`
                                                  : ''
                                          }
                                            style={{
                                                display: 'block',
                                                margin: '2px auto',
                                            }}
                                        />
                                        <span className="nm">
                                            {user
                                                ? user.name
                                                      .split(' ')
                                                      .slice(0, 10)
                                                      .join(' ')
                                                : 'Ashad'}
                                        </span>
                                    </DropdownItem>
            <DropdownItem
                                        tag={Link}
                                        to={'/admin/astrologers'}
                                    >
                                        Astrologers
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => dispatch(adminLogout())}
                                    >
                                        <i className="fa fa-sign-out-alt"></i>
                                        logout
                                    </DropdownItem>
                                    </DropdownMenu>
                                    </Dropdown>
                                    </NavLink>
                                    </NavItem> */}
        </Nav>
      </Collapse>
    </Navbar>
  );
}
